import skuApi from 'request/api/skuApi';
import stockLossApi from 'request/api/stockLossApi';
import stockSurplusApi from 'request/api/stockSurplusApi';
import replenishmentApi from 'request/api/replenishmentApi';
import stockAbstractApi from 'request/api/stockAbstractApi';
import stockCheckApi from 'request/api/stockCheckApi';
import stockReceiveApi from 'request/api/stockReceiveApi';
import stockCheckDiffApi from 'request/api/stockCheckDiffApi';
import skuChangeApi from 'request/api/skuChangeApi';
import goodsApi from 'request/api/goodsApi';
import deptApi from 'request/api/deptApi';
import adjustAbstractApi from 'request/api/adjustAbstractApi';
import advertManageApi from 'request/api/advertManageApi';
import deptGoodsDayTradeRefundSumApi from 'request/api/deptGoodsDayTradeRefundSumApi';
import deptGoodsDayTradeSumApi from 'request/api/deptGoodsDayTradeSumApi';
import deptGroupApi from 'request/api/deptGroupApi';
import deptPrivilegeApi from 'request/api/deptPrivilegeApi';
import goodsPlanSellApi from 'request/api/goodsPlanSellApi';
import goodsPriceChangeApi from 'request/api/goodsPriceChangeApi';
import goodsStock from 'request/api/goodsStock';
import memberImportApi from 'request/api/memberImportApi';
import payTemplateApi from 'request/api/payTemplateApi';
import purchaseOrderApi from 'request/api/purchaseOrderApi';
import reviewApi from 'request/api/reviewApi';
import roleApi from 'request/api/roleApi';
import staffApi from 'request/api/staffApi';
import stockAllocateApi from 'request/api/stockAllocateApi';
import wmaCostPriceChangeApi from 'request/api/wmaCostPriceChangeApi';
import wmaStockCostChangeReportApi from 'request/api/wmaStockCostChangeReportApi';
import distributionMemberDetailApi from 'request/api/distributionMemberDetailApi';
import distributionMemberDeptCountApi from 'request/api/distributionMemberDeptCountApi';
export default {
    adjustAbstractApi,
    advertManageApi,
    deptGoodsDayTradeRefundSumApi,
    deptGoodsDayTradeSumApi,
    deptGroupApi,
    deptPrivilegeApi,
    goodsPlanSellApi,
    goodsPriceChangeApi,
    goodsStock,
    memberImportApi,
    payTemplateApi,
    purchaseOrderApi,
    reviewApi,
    roleApi,
    staffApi,
    stockAllocateApi,
    wmaCostPriceChangeApi,
    wmaStockCostChangeReportApi,
    skuApi,
    stockLossApi,
    stockSurplusApi,
    stockAbstractApi,
    replenishmentApi,
    stockCheckApi,
    stockReceiveApi,
    stockCheckDiffApi,
    skuChangeApi,
    goodsApi,
    deptApi,
    distributionMemberDetailApi,
    distributionMemberDeptCountApi,
};

import {
    delAfterConfirmed,
    get,
    patchAfterConfirmed,
    patchByFormDataAfterConfirmed,
    postAfterConfirmed,
    postByFormDataAfterConfirmed,
} from '../http';

const baseUrl = 'repayDeptGoods';

const page = (queryParam) => get(`${baseUrl}/pageExtend`, queryParam, true);

const detail = (code) => get(`${baseUrl}/bizDetail/${code}`);

const create = (createParam) => postAfterConfirmed(`${baseUrl}/createExtend`, createParam);

const createByFormData = (createParam) => postByFormDataAfterConfirmed(`${baseUrl}/createExtend`, createParam);

const update = (code, updateParam) => patchAfterConfirmed(`${baseUrl}/patchExtend/${code}`, updateParam);

const updateByFormData = (code, updateParam) =>
    patchByFormDataAfterConfirmed(`${baseUrl}/patchExtend/${code}`, updateParam);

const deleteBiz = (code) => delAfterConfirmed(`${baseUrl}/delete`, { code: code });

export default { baseUrl, page, detail, create, createByFormData, update, updateByFormData, deleteBiz };

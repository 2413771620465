import { get, post } from 'request/http';
import Util from 'js/Util';

const baseUrl = '/merchandising/getGoods';

const page = (param) => {
    return get(`${baseUrl}/page`, param, true);
};

const createReceiveStockBiz = (codes = []) => {
    if (Util.isEmpty(codes) || codes.length === 0) {
        return new Promise(() => {});
    }
    return post(`${baseUrl}/stockReceive`, { codes: codes });
};

export default { baseUrl, page, createReceiveStockBiz };

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'login',
            component: () => import('./views/Login.vue'),
            meta: { allowBack: true },
        },
        {
            path: '/chooseGroup',
            name: 'chooseGroup',
            component: () => import('./views/ChooseGroup.vue'),
            meta: { allowBack: true },
        },
        {
            name: 'aliPayAuthSuccess',
            path: '/aliPayAuthSuccess',
            meta: { title: '授权成功', keepAlive: false },
            component: () => import('./views/menu/finance/AliPayAuthSuccess.vue'),
        },
        {
            path: '/main',
            name: 'main',
            component: () => import('./views/Main.vue'),
            children: [
                {
                    path: '/promotion/nPromotionDetail',
                    name: 'menu.promotion.npromotion.detail',
                    component: () => import('./views/menu/promotion/np/NPromotionDetail.vue'),
                    meta: { allowBack: true, title: '促销详情', keepAlive: true },
                    props: (route) => ({ promotionCode: route.params.editPromotionCode }),
                },
                {
                    path: '/promotion',
                    name: 'menu.promotion.npromotion',
                    component: () => import('./views/menu/promotion/Promotion.vue'),
                    meta: { allowBack: true, title: '促销方案（新）', keepAlive: true },
                },
                {
                    path: '/promotion/createPromotionType',
                    name: 'menu.promotion.npromotion.create.type',
                    component: () => import('./views/menu/promotion/np/CreatePromotionType.vue'),
                    meta: { allowBack: true, title: '新建促销-选择促销类型' },
                },
                {
                    path: '/promotion/CreatePromotionSpecialOffer',
                    name: 'menu.promotion.npromotion.create.specialOffer',
                    component: () => import('./views/menu/promotion/np/CreatePromotionSpecialOffer.vue'),
                    meta: { keepAlive: true, allowBack: true, title: '新建/编辑促销-单品件数折扣特价' },
                    props: (route) => ({ editPromotionCode: route.params.editPromotionCode }),
                },
                {
                    path: '/promotion/CreatePromotionBuyoutPrice',
                    name: 'menu.promotion.npromotion.create.buyoutPrice',
                    component: () => import('./views/menu/promotion/np/CreatePromotionBuyoutPrice.vue'),
                    meta: { keepAlive: true, allowBack: true, title: '新建/编辑促销-任选一口价' },
                    props: (route) => ({ editPromotionCode: route.params.editPromotionCode }),
                },
                {
                    path: '/promotion/CreatePromotionFixBuyout',
                    name: 'menu.promotion.npromotion.create.fixBuyOut',
                    component: () => import('./views/menu/promotion/np/CreatePromotionFixBuyout.vue'),
                    meta: { keepAlive: true, allowBack: true, title: '新建/编辑促销-固定一口价' },
                    props: (route) => ({ editPromotionCode: route.params.editPromotionCode }),
                },
                {
                    path: '/promotion/CreatePromotionFixDiscount',
                    name: 'menu.promotion.npromotion.create.fixDiscount',
                    component: () => import('./views/menu/promotion/np/CreatePromotionFixDiscount.vue'),
                    meta: { keepAlive: true, allowBack: true, title: '新建/编辑促销-任选件数折扣' },
                    props: (route) => ({ editPromotionCode: route.params.editPromotionCode }),
                },
                {
                    path: '/promotion/CreatePromotionGiveaway',
                    name: 'menu.promotion.npromotion.create.giveaway',
                    component: () => import('./views/menu/promotion/np/CreatePromotionGiveaway.vue'),
                    meta: { keepAlive: true, allowBack: true, title: '新建/编辑促销-单品满赠' },
                    props: (route) => ({ editPromotionCode: route.params.editPromotionCode }),
                },
                {
                    path: '/promotion/CreatePromotionLaunch',
                    name: 'menu.promotion.npromotion.create.launch',
                    component: () => import('./views/menu/promotion/np/CreatePromotionLaunch.vue'),
                    meta: { allowBack: true, title: '新建/编辑促销-活动投放' },
                    props: (route) => ({ promotionCode: route.params.promotionCode }),
                },
                {
                    name: 'console',
                    path: 'menu/console',
                    meta: { title: '首页', keepAlive: true, tabIndex: 0 },
                    component: () => import('./views/menu/Console.vue'),
                },
                {
                    name: 'guide',
                    path: 'menu/guide',
                    //tabIndex:tab标签位置下标
                    meta: { title: '流程引导', keepAlive: true, tabIndex: 1 },
                    component: () => import('./views/menu/Guide.vue'),
                },
                {
                    name: 'deptOperateGoodsGuide',
                    path: 'menu/guide/deptOperateGoodsGuide',
                    meta: { title: '新门店导入前系统数据', keepAlive: true },
                    component: () => import('./views/menu/guide/DeptOperateGoodsGuide'),
                },
                {
                    name: 'deptOperateStaffGuide',
                    path: 'menu/guide/deptOperateStaffGuide',
                    meta: { title: '添加员工与绑定角色', keepAlive: true },
                    component: () => import('./views/menu/guide/DeptOperateStaffGuide'),
                },
                {
                    name: 'deptImportMemberGuide',
                    path: 'menu/guide/deptImportMemberGuide',
                    meta: { title: '导入旧系统会员', keepAlive: true },
                    component: () => import('./views/menu/guide/DeptImportMemberGuide'),
                },
                {
                    name: 'allocateGoodsGuide',
                    path: 'menu/guide/allocateGoodsGuide',
                    meta: { title: '调拨', keepAlive: true },
                    component: () => import('./views/menu/guide/AllocateGoodsGuide'),
                },
                {
                    name: 'purchaseGoodsGuide',
                    path: 'menu/guide/purchaseGoodsGuide',
                    meta: { title: '采购与入库', keepAlive: true },
                    component: () => import('./views/menu/guide/PurchaseGoodsGuide'),
                },
                {
                    name: 'cashTradeGuide',
                    path: 'menu/guide/cashTradeGuide',
                    meta: { title: '收银/预售单据查看', keepAlive: true },
                    component: () => import('./views/menu/guide/CashTradeGuide'),
                },
                {
                    name: 'saleOrderGuide',
                    path: 'menu/guide/saleOrderGuide',
                    meta: { title: '批发销售/出库单', keepAlive: true },
                    component: () => import('./views/menu/guide/SaleOrderGuide'),
                },
                {
                    name: 'dailyMonthSettleGuide',
                    path: 'menu/guide/dailyMonthSettleGuide',
                    meta: { title: '日结/月结', keepAlive: true },
                    component: () => import('./views/menu/guide/DailyMonthSettleGuide'),
                },
                {
                    name: 'stockLossSurplusGuide',
                    path: 'menu/guide/stockLossSurplusGuide',
                    meta: { title: '报损报溢', keepAlive: true },
                    component: () => import('./views/menu/guide/StockLossSurplusGuide'),
                },
                {
                    name: 'receiveGuide',
                    path: 'menu/guide/receiveGuide',
                    meta: { title: '内购/领用', keepAlive: true },
                    component: () => import('./views/menu/guide/ReceiveGuide'),
                },
                {
                    name: 'stockCheckGuide',
                    path: 'menu/guide/stockCheckGuide',
                    meta: { title: '盘点', keepAlive: true },
                    component: () => import('./views/menu/guide/StockCheckGuide'),
                },
                {
                    name: 'stockInOutReportGuide',
                    path: 'menu/guide/stockInOutReportGuide',
                    meta: { title: '出入库统计', keepAlive: true },
                    component: () => import('./views/menu/guide/StockInOutReportGuide'),
                },
                {
                    name: 'purchaseReportGuide',
                    path: 'menu/guide/purchaseReportGuide',
                    meta: { title: '采购统计', keepAlive: true },
                    component: () => import('./views/menu/guide/PurchaseReportGuide'),
                },
                {
                    name: 'merchandisingReportGuide',
                    path: 'menu/guide/merchandisingReportGuide',
                    meta: { title: '商品计划统计', keepAlive: true },
                    component: () => import('./views/menu/guide/MerchandisingReportGuide'),
                },
                {
                    name: 'addMemberGuide',
                    path: 'menu/guide/addMemberGuide',
                    meta: { title: '添加会员', keepAlive: true },
                    component: () => import('./views/menu/guide/AddMemberGuide'),
                },
                {
                    name: 'appPopularizeStaffGuide',
                    path: 'menu/guide/appPopularizeStaffGuide',
                    meta: { title: '成为app推广员工', keepAlive: true },
                    component: () => import('./views/menu/guide/AppPopularizeStaffGuide'),
                },
                {
                    name: 'registerWechatAlipayGuide',
                    path: 'menu/guide/registerWechatAlipayGuide',
                    meta: { title: '注册微信/支付宝', keepAlive: true },
                    component: () => import('./views/menu/guide/RegisterWechatAlipayGuide'),
                },
                {
                    name: 'applyDeptGuide',
                    path: 'menu/guide/applyDeptGuide',
                    meta: { title: '申请新门店', keepAlive: true },
                    component: () => import('./views/menu/guide/ApplyDeptGuide'),
                },
                {
                    name: 'menu.goods.sku',
                    path: 'menu/goods/sku',
                    meta: { title: '商品基本信息管理', keepAlive: true },
                    component: () => import('./views/menu/goods/Sku.vue'),
                },
                {
                    name: 'menu.goods.sku.edit',
                    path: 'menu/goods/sku/edit/:code',
                    meta: { title: '编辑SKU', keepAlive: false },
                    component: () => import('./views/menu/goods/sku/SkuEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.sku.detail',
                    path: 'menu/goods/sku/detail/:code',
                    meta: { title: 'SKU详情', keepAlive: false },
                    component: () => import('./views/menu/goods/sku/SkuDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.sku.batchCreate',
                    path: 'menu/goods/sku/detail/batchCreate',
                    meta: { title: '批量新建SKU', keepAlive: true },
                    component: () => import('./views/menu/goods/sku/BatchCreate.vue'),
                },

                {
                    name: 'menu.goods.goods',
                    path: 'menu/goods/goods',
                    meta: { title: '可售商品管理', keepAlive: true },
                    component: () => import('./views/menu/goods/Goods.vue'),
                },
                {
                    name: 'menu.goods.goods.detail',
                    path: 'menu/goods/goods/detail/:code',
                    meta: { title: '商品详情', keepAlive: false },
                    component: () => import('./views/menu/goods/goods/GoodsDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.goods.edit',
                    path: 'menu/goods/goods/edit/:code',
                    meta: { title: '编辑商品', keepAlive: false },
                    component: () => import('./views/menu/goods/goods/GoodsEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.goods.create',
                    path: 'menu/goods/goods/create/',
                    meta: { title: '新建商品', keepAlive: true },
                    component: () => import('./views/menu/goods/goods/GoodsCreate.vue'),
                },
                {
                    name: 'menu.sku.offline.banned',
                    path: 'menu/sku/offline/banned',
                    meta: { title: '禁售商品设置', keepAlive: true },
                    component: () => import('./views/menu/goods/Forbid.vue'),
                },
                {
                    name: 'menu.goods.brand',
                    path: 'menu/goods/brand',
                    meta: { title: '品牌管理', keepAlive: true },
                    component: () => import('./views/menu/goods/Brand.vue'),
                },
                {
                    name: 'menu.goods.brand.detail',
                    path: 'menu/goods/brand/detail',
                    meta: { title: '品牌详情', keepAlive: false },
                    component: () => import('./views/menu/goods/brand/BrandDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.brand.edit',
                    path: 'menu/goods/brand/edit',
                    meta: { title: '编辑品牌', keepAlive: false },
                    component: () => import('./views/menu/goods/brand/BrandEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.brand.create',
                    path: 'menu/goods/brand/create/',
                    meta: { title: '新建品牌', keepAlive: true },
                    component: () => import('./views/menu/goods/brand/BrandCreate.vue'),
                },
                {
                    name: 'menu.goods.category',
                    path: 'menu/goods/category',
                    meta: { title: '类目管理', keepAlive: true },
                    component: () => import('./views/menu/goods/Category.vue'),
                },
                {
                    name: 'menu.goods.category.detail',
                    path: 'menu/goods/category/detail/:code',
                    meta: { title: '类目详情', keepAlive: false },
                    component: () => import('./views/menu/goods/category/CategoryDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.category.edit',
                    path: 'menu/goods/category/edit/:code',
                    meta: { title: '编辑类目', keepAlive: false },
                    component: () => import('./views/menu/goods/category/CategoryEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.category.create',
                    path: 'menu/goods/category/create/',
                    meta: { title: '新建类目', keepAlive: true },
                    component: () => import('./views/menu/goods/category/CategoryCreate.vue'),
                },
                {
                    name: 'menu.goods.supplier',
                    path: 'menu/goods/supplier',
                    meta: { title: '供应商管理', keepAlive: true },
                    component: () => import('./views/menu/goods/Supplier.vue'),
                },
                {
                    name: 'menu.goods.supplier.detail',
                    path: 'menu/goods/supplier/detail',
                    meta: { title: '供应商详情', keepAlive: false },
                    component: () => import('./views/menu/goods/supplier/SupplierDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.supplier.edit',
                    path: 'menu/goods/supplier/edit',
                    meta: { title: '编辑供应商', keepAlive: false },
                    component: () => import('./views/menu/goods/supplier/SupplierEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.supplier.create',
                    path: 'menu/goods/supplier/create/',
                    meta: { title: '新建供应商', keepAlive: true },
                    component: () => import('./views/menu/goods/supplier/SupplierCreate.vue'),
                },
                {
                    name: 'menu.goods.price',
                    path: 'menu/goods/price',
                    meta: { title: '商品调价', keepAlive: true },
                    component: () => import('./views/menu/goods/Price.vue'),
                },
                {
                    name: 'menu.goods.price.detail',
                    path: 'menu/goods/price/detail',
                    meta: { title: '商品调价详情', keepAlive: false },
                    component: () => import('./views/menu/goods/price/PriceDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.goods.price.edit',
                    path: 'menu/goods/price/edit',
                    meta: { title: '商品调价编辑', keepAlive: false },
                    component: () => import('./views/menu/goods/price/PriceEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.goods.price.create',
                    path: 'menu/goods/price/create',
                    meta: { title: '新建商品调价', keepAlive: true },
                    component: () => import('./views/menu/goods/price/PriceCreate.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.price.batchChangePrice',
                    path: 'menu/goods/price/batchChangePrice',
                    meta: { title: '批量调价', keepAlive: true },
                    component: () => import('./views/menu/goods/price/PriceBatchChangePrice.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.wma.costPrice',
                    path: 'menu/goods/wma/costPrice',
                    meta: { title: '商品移动加权成本调价', keepAlive: true },
                    component: () => import('./views/menu/goods/WmaCostPrice.vue'),
                },
                {
                    name: 'menu.goods.wma.costPrice.create',
                    path: 'menu/goods/wma/costPrice/create',
                    meta: { title: '新建商品移动加权成本调价', keepAlive: true },
                    component: () => import('./views/menu/goods/wmaCostPrice/WmaCostPriceCreate.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.wma.costPrice.edit',
                    path: 'menu/goods/wma/costPrice/edit',
                    meta: { title: '编辑商品移动加权成本调价', keepAlive: false },
                    component: () => import('./views/menu/goods/wmaCostPrice/WmaCostPriceEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.goods.wma.costPrice.detail',
                    path: 'menu/goods/wma/costPrice/detail',
                    meta: { title: '商品移动加权成本调价详情', keepAlive: false },
                    component: () => import('./views/menu/goods/wmaCostPrice/WmaCostPriceDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },

                {
                    name: 'menu.report.wma.cost.change',
                    path: 'menu/report/wma/cost/change',
                    meta: { title: '移动加权进销存汇总', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('views/menu/report/WmaDeptCostSummary.vue'),
                },
                {
                    name: 'menu.report.wma.cost.change.detail',
                    path: 'menu/report/wma/cost/change/detail',
                    meta: { title: '移动加权进销存明细', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('views/menu/report/WmaGoodsCostSummary.vue'),
                },
                {
                    name: 'menu.report.wma.cost.change.detail.history',
                    path: 'menu/report/wma/cost/change/detail/history',
                    meta: { title: '移动加权库存成本流水', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('./views/menu/report/WmaStockCostChangeDetailHistory.vue'),
                },
                {
                    name: 'menu.report.wma.cost.change.grossProfit',
                    path: 'menu/report/wma/cost/change/grossProfit',
                    meta: { title: '移动加权机构毛利', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('views/menu/report/WmaDeptGrossProfit.vue'),
                },
                {
                    name: 'menu.report.wma.cost.change.detail.grossProfit',
                    path: 'menu/report/wma/cost/change/detail/grossProfit',
                    meta: { title: '移动加权商品毛利', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('views/menu/report/WmaGoodsGrossProfit.vue'),
                },
                {
                    name: 'menu.goods.skuPurchase',
                    path: 'menu/goods/skuPurchase',
                    meta: { title: '单方采购设置', keepAlive: true },
                    component: () => import('./views/menu/goods/SkuPurchase.vue'),
                },
                {
                    name: 'menu.goods.SkuPurchase.detail',
                    path: 'menu/goods/SkuPurchase/detail',
                    meta: { title: '单方采购设置详情', keepAlive: false },
                    component: () => import('./views/menu/goods/skuPurchase/SkuPurchaseDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.goods.skuPurchase.edit',
                    path: 'menu/goods/skuPurchase/edit',
                    meta: { title: '编辑单方采购设置', keepAlive: false },
                    component: () => import('./views/menu/goods/skuPurchase/SkuPurchaseEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.purchase.sysIn',
                    path: 'menu/purchase/sysIn',
                    meta: { title: '初始化数据', keepAlive: true },
                    component: () => import('./views/menu/goods/SysIn.vue'),
                },
                {
                    name: 'menu.purchase.sysIn.create',
                    path: 'menu/purchase/sysIn/create',
                    meta: { title: '新建初始化商品数据', keepAlive: true },
                    component: () => import('./views/menu/goods/sysIn/SysInCreate.vue'),
                },
                {
                    name: 'menu.purchase.sysIn.detail',
                    path: 'menu/purchase/sysIn/detail',
                    meta: { title: '初始化商品数据详情', keepAlive: false },
                    component: () => import('./views/menu/goods/sysIn/SysInDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.purchase.sysIn.edit',
                    path: 'menu/purchase/sysIn/edit',
                    meta: { title: '编辑初始化商品数据', keepAlive: false },
                    component: () => import('./views/menu/goods/sysIn/SysInEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.sysOut',
                    path: 'menu/purchase/sysOut',
                    meta: { title: '初始化退货', keepAlive: true },
                    component: () => import('./views/menu/goods/SysOut.vue'),
                },
                {
                    name: 'menu.purchase.sysOut.detail',
                    path: 'menu/purchase/sysOut/detail',
                    meta: { title: '初始化商品退货详情', keepAlive: false },
                    component: () => import('./views/menu/goods/sysOut/SysOutDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.purchase.sysOut.edit',
                    path: 'menu/purchase/sysOut/edit',
                    meta: { title: '编辑初始化商品退货', keepAlive: false },
                    component: () => import('./views/menu/goods/sysOut/SysOutEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.purchase.sysOut.create',
                    path: 'menu/purchase/sysOut/create',
                    meta: { title: '新建初始化商品退货', keepAlive: false },
                    component: () => import('./views/menu/goods/sysOut/SysOutCreate.vue'),
                },
                {
                    name: 'menu.stock.loss',
                    path: 'menu/stock/loss',
                    meta: { title: '报损调整', keepAlive: true },
                    component: () => import('./views/menu/stock/Loss.vue'),
                },
                {
                    name: 'menu.stock.loss.detail',
                    path: 'menu/stock/loss/detail',
                    meta: { title: '报损详情', keepAlive: false },
                    component: () => import('./views/menu/stock/loss/LossDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.loss.edit',
                    path: 'menu/stock/loss/edit',
                    meta: { title: '编辑报损', keepAlive: false },
                    component: () => import('./views/menu/stock/loss/LossEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.loss.create',
                    path: 'menu/stock/loss/create',
                    meta: { title: '新建报损', keepAlive: true },
                    component: () => import('./views/menu/stock/loss/LossCreate.vue'),
                },

                {
                    name: 'menu.stock.surplus',
                    path: 'menu/stock/surplus',
                    meta: { title: '报溢调整', keepAlive: true },
                    component: () => import('./views/menu/stock/Surplus.vue'),
                },
                {
                    name: 'menu.stock.surplus.detail',
                    path: 'menu/stock/surplus/detail',
                    meta: { title: '报溢详情', keepAlive: false },
                    component: () => import('./views/menu/stock/surplus/SurplusDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.surplus.edit',
                    path: 'menu/stock/surplus/edit',
                    meta: { title: '编辑报溢', keepAlive: false },
                    component: () => import('./views/menu/stock/surplus/SurplusEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.surplus.create',
                    path: 'menu/stock/surplus/create',
                    meta: { title: '新建报溢', keepAlive: true },
                    component: () => import('./views/menu/stock/surplus/SurplusCreate.vue'),
                },

                {
                    name: 'menu.stock.abstract',
                    path: 'menu/stock/abstract',
                    meta: { title: '摘要管理', keepAlive: true },
                    component: () => import('./views/menu/stock/Abstract.vue'),
                },
                {
                    name: 'menu.stock.abstract.edit',
                    path: 'menu/stock/abstract/edit/:code',
                    meta: { title: '编辑摘要', keepAlive: false },
                    component: () => import('./views/menu/stock/abstract/AbstractEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.abstract.create',
                    path: 'menu/stock/abstract/create',
                    meta: { title: '新建摘要', keepAlive: true },
                    component: () => import('./views/menu/stock/abstract/AbstractCreate.vue'),
                },
                {
                    name: 'menu.stock.check',
                    path: 'menu/stock/check',
                    meta: { title: '盘点管理', keepAlive: true },
                    component: () => import('./views/menu/stock/Check.vue'),
                },
                {
                    name: 'menu.stock.check.detail',
                    path: 'menu/stock/check/detail/:code',
                    meta: { title: '盘点详情', keepAlive: false },
                    component: () => import('./views/menu/stock/check/CheckDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.check.edit',
                    path: 'menu/stock/check/edit/:code',
                    meta: { title: '编辑盘点', keepAlive: false },
                    component: () => import('./views/menu/stock/check/CheckEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.check.create',
                    path: 'menu/stock/check/create',
                    meta: { title: '新建盘点', keepAlive: true },
                    component: () => import('./views/menu/stock/check/CheckCreate.vue'),
                },
                {
                    name: 'menu.stock.check.review',
                    path: 'menu/stock/check/review',
                    meta: { title: '盘点审核', keepAlive: false },
                    component: () => import('./views/menu/stock/check/CheckReview.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.check.input',
                    path: 'menu/stock/check/input',
                    meta: { title: '盘点录入', keepAlive: false },
                    component: () => import('./views/menu/stock/check/CheckInput.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.checkDiff',
                    path: 'menu/stock/checkDiff',
                    meta: { title: '盘点差异管理', keepAlive: false },
                    component: () => import('./views/menu/stock/CheckDiff.vue'),
                },
                {
                    name: 'menu.stock.checkDiff.detail',
                    path: 'menu/stock/checkDiff/detail/:code',
                    meta: { title: '盘点差异详情', keepAlive: false },
                    component: () => import('./views/menu/stock/checkDiff/CheckDiffDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.receive',
                    path: 'menu/stock/receive',
                    meta: { title: '内购管理', keepAlive: true },
                    component: () => import('./views/menu/stock/Receive.vue'),
                },
                {
                    name: 'menu.stock.receive.create',
                    path: 'menu/stock/receive/create',
                    meta: { title: '新建内购', keepAlive: true },
                    component: () => import('./views/menu/stock/receive/ReceiveCreate.vue'),
                },
                {
                    name: 'menu.stock.receive.detail',
                    path: 'menu/stock/receive/detail/:code',
                    meta: { title: '内购详情', keepAlive: false },
                    component: () => import('./views/menu/stock/receive/ReceiveDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.receive.edit',
                    path: 'menu/stock/receive/edit/:code',
                    meta: { title: '编辑内购', keepAlive: false },
                    component: () => import('./views/menu/stock/receive/ReceiveEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.allocate',
                    path: 'menu/stock/allocate',
                    meta: { title: '调拨申请', keepAlive: true },
                    component: () => import('./views/menu/stock/Allocate.vue'),
                },
                {
                    name: 'menu.stock.allocate.detail',
                    path: 'menu/stock/allocate/detail/:code',
                    meta: { title: '调拨申请详情', keepAlive: false },
                    component: () => import('./views/menu/stock/allocate/AllocateDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.stock.allocate.edit',
                    path: 'menu/stock/allocate/edit/:code',
                    meta: { title: '编辑调拨申请', keepAlive: false },
                    component: () => import('./views/menu/stock/allocate/AllocateEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.stock.allocate.create',
                    path: 'menu/stock/allocate/create',
                    meta: { title: '新建调拨申请', keepAlive: true },
                    component: () => import('./views/menu/stock/allocate/AllocateCreate.vue'),
                },
                {
                    name: 'menu.stock.allocateIn',
                    path: 'menu/stock/allocateIn',
                    meta: { title: '调拨入库', keepAlive: true },
                    component: () => import('./views/menu/stock/AllocateIn.vue'),
                },
                {
                    name: 'menu.stock.allocateIn.detail',
                    path: 'menu/stock/allocateIn/detail/:code',
                    meta: { title: '调拨入库详情', keepAlive: false },
                    component: () => import('./views/menu/stock/allocateIn/AllocateInDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.stock.allocateOut',
                    path: 'menu/stock/allocateOut',
                    meta: { title: '调拨出库', keepAlive: true },
                    component: () => import('./views/menu/stock/AllocateOut.vue'),
                },
                {
                    name: 'menu.stock.allocateOut.detail',
                    path: 'menu/stock/allocateOut/detail/:code',
                    meta: { title: '调拨出库详情', keepAlive: false },
                    component: () => import('./views/menu/stock/allocateOut/AllocateOutDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.purchase.order',
                    path: 'menu/purchase/order',
                    meta: { title: '采购订单', keepAlive: true },
                    component: () => import('./views/menu/purchase/PurchaseOrder.vue'),
                },
                {
                    name: 'menu.purchase.order.detail',
                    path: 'menu/purchase/order/detail/:code',
                    meta: { title: '采购订单详情', keepAlive: false },
                    component: () => import('./views/menu/purchase/order/PurchaseOrderDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.order.edit',
                    path: 'menu/purchase/order/edit/:code',
                    meta: { title: '编辑采购订单', keepAlive: false },
                    component: () => import('./views/menu/purchase/order/PurchaseOrderEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.order.create',
                    path: 'menu/purchase/order/create',
                    meta: { title: '新建采购订单', keepAlive: true },
                    component: () => import('./views/menu/purchase/order/PurchaseOrderCreate.vue'),
                },
                {
                    name: 'menu.purchase.in',
                    path: 'menu/purchase/in',
                    meta: { title: '采购入库', keepAlive: true },
                    component: () => import('./views/menu/purchase/PurchaseIn.vue'),
                },
                {
                    name: 'menu.purchase.in.detail',
                    path: 'menu/purchase/in/detail/:code',
                    meta: { title: '采购入库详情', keepAlive: false },
                    component: () => import('./views/menu/purchase/in/PurchaseInDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.refund',
                    path: 'menu/purchase/refund',
                    meta: { title: '退货申请', keepAlive: true },
                    component: () => import('./views/menu/purchase/PurchaseRefund.vue'),
                },
                {
                    name: 'menu.purchase.refund.detail',
                    path: 'menu/purchase/refund/detail/:code',
                    meta: { title: '采购退货申请详情', keepAlive: false },
                    component: () => import('./views/menu/purchase/refund/PurchaseRefundDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.refund.edit',
                    path: 'menu/purchase/refund/edit/:code',
                    meta: { title: '编辑采购退货申请', keepAlive: false },
                    component: () => import('./views/menu/purchase/refund/PurchaseRefundEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.refund.create',
                    path: 'menu/purchase/refund/create',
                    meta: { title: '新建采购退货申请', keepAlive: true },
                    component: () => import('./views/menu/purchase/refund/PurchaseRefundCreate.vue'),
                },
                {
                    name: 'menu.purchase.refundOut',
                    path: 'menu/purchase/refundOut',
                    meta: { title: '退货出库', keepAlive: true },
                    component: () => import('./views/menu/purchase/PurchaseRefundOut.vue'),
                },
                {
                    name: 'menu.purchase.refundOut.detail',
                    path: 'menu/purchase/refundOut/detail/:code',
                    meta: { title: '采购退货出库详情', keepAlive: false },
                    component: () => import('./views/menu/purchase/refundOut/RefundOutDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.purchase.selfOut',
                    path: 'menu/purchase/selfOut',
                    meta: { title: '单方采购退货', keepAlive: true },
                    component: () => import('./views/menu/purchase/SelfOut.vue'),
                },
                {
                    name: 'menu.purchase.selfOut.detail',
                    path: 'menu/purchase/selfOut/detail/:code',
                    meta: { title: '单方采购退货详情', keepAlive: false },
                    component: () => import('./views/menu/purchase/selfOut/SelfOutDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.selfOut.edit',
                    path: 'menu/purchase/selfOut/edit/:code',
                    meta: { title: '编辑单方采购退货', keepAlive: false },
                    component: () => import('./views/menu/purchase/selfOut/SelfOutEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.selfOut.create',
                    path: 'menu/purchase/selfOut/create',
                    meta: { title: '新建单方采购退货', keepAlive: true },
                    component: () => import('./views/menu/purchase/selfOut/SelfOutCreate.vue'),
                },
                {
                    name: 'menu.purchase.selfIn',
                    path: 'menu/purchase/selfIn',
                    meta: { title: '单方采购入库', keepAlive: true },
                    component: () => import('./views/menu/purchase/SelfIn.vue'),
                },
                {
                    name: 'menu.purchase.selfIn.detail',
                    path: 'menu/purchase/selfIn/detail/:code',
                    meta: { title: '单方采购入库详情', keepAlive: false },
                    component: () => import('./views/menu/purchase/selfIn/SelfInDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.selfIn.edit',
                    path: 'menu/purchase/selfIn/edit/:code',
                    meta: { title: '编辑单方采购入库', keepAlive: false },
                    component: () => import('./views/menu/purchase/selfIn/SelfInEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.purchase.selfIn.create',
                    path: 'menu/purchase/selfIn/create',
                    meta: { title: '新建单方采购入库', keepAlive: true },
                    component: () => import('./views/menu/purchase/selfIn/SelfInCreate.vue'),
                },
                {
                    name: 'menu.reserve.member',
                    path: 'menu/reserve/member',
                    meta: { title: '会员订购', keepAlive: true },
                    component: () => import('./views/menu/reserve/Member.vue'),
                },
                {
                    name: 'menu.purchase.member.create',
                    path: 'menu/purchase/member/create',
                    meta: { title: '新建会员订购', keepAlive: true },
                    component: () => import('./views/menu/purchase/member/MemberCreate.vue'),
                },
                {
                    name: 'menu.purchase.member.order',
                    path: 'menu/purchase/member/order',
                    meta: { title: '会员订购订单', keepAlive: false },
                    component: () => import('./views/menu/purchase/member/MemberOrder.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.purchase.memberOrder.create',
                    path: 'menu/purchase/memberOrder/create',
                    meta: { title: '新建会员订购订单', keepAlive: false },
                    component: () => import('./views/menu/purchase/member/MemberOrderCreate.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.order',
                    path: 'menu/sale/order',
                    meta: { title: '批发订单', keepAlive: true },
                    component: () => import('./views/menu/sale/Order.vue'),
                },
                {
                    name: 'menu.sale.order.detail',
                    path: 'menu/sale/order/detail/:code',
                    meta: { title: '批发订单详情', keepAlive: false },
                    component: () => import('./views/menu/sale/order/OrderDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.sale.order.edit',
                    path: 'menu/sale/order/edit/:code',
                    meta: { title: '编辑批发订单', keepAlive: false },
                    component: () => import('./views/menu/sale/order/OrderEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.sale.order.create',
                    path: 'menu/sale/order/create',
                    meta: { title: '新建批发订单', keepAlive: true },
                    component: () => import('views/menu/sale/order/OrderCreate.vue'),
                },
                {
                    name: 'menu.sale.systemcOrderDelivery',
                    path: 'menu/sale/systemcOrderDelivery',
                    meta: { title: 'c系统订单配送单', keepAlive: true },
                    component: () => import('./views/menu/sale/SystemcOrderDelivery.vue'),
                },
                {
                    name: 'menu.sale.systemcOrderDelivery.detail',
                    path: 'menu/sale/systemcOrderDelivery/detail/:code',
                    meta: { title: 'c系统订单配送单详情', keepAlive: false },
                    component: () => import('./views/menu/sale/systemcOrderDelivery/SystemcOrderDeliveryDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.systemcRefundDelivery',
                    path: 'menu/sale/systemcRefundDelivery',
                    meta: { title: 'c系统退款退货单', keepAlive: true },
                    component: () => import('./views/menu/sale/SystemcRefundDelivery.vue'),
                },
                {
                    name: 'menu.sale.systemcRefundDelivery.detail',
                    path: 'menu/sale/systemcRefundDelivery/detail/:code',
                    meta: { title: 'c系统退款退货单详情', keepAlive: false },
                    component: () => import('./views/menu/sale/systemcRefundDelivery/SystemcRefundDeliveryDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.out',
                    path: 'menu/sale/out',
                    meta: { title: '批发出库', keepAlive: true },
                    component: () => import('./views/menu/sale/Out.vue'),
                },
                {
                    name: 'menu.sale.out.detail',
                    path: 'menu/sale/out/detail/:code',
                    meta: { title: '批发出库详情', keepAlive: false },
                    component: () => import('./views/menu/sale/out/OutDetail.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.sale.refund',
                    path: 'menu/sale/refund',
                    meta: { title: '批发退货申请', keepAlive: true },
                    component: () => import('./views/menu/sale/Refund.vue'),
                },
                {
                    name: 'menu.sale.refund.detail',
                    path: 'menu/sale/refund/detail/:code',
                    meta: { title: '批发退货申请详情', keepAlive: false },
                    component: () => import('./views/menu/sale/refund/RefundDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.refund.edit',
                    path: 'menu/sale/refund/edit/:code',
                    meta: { title: '编辑自建退货申请', keepAlive: false },
                    component: () => import('views/menu/sale/refund/RepoSelfRefundEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.sale.refund.create',
                    path: 'menu/sale/refund/create',
                    meta: { title: '新建退货申请', keepAlive: true },
                    component: () => import('views/menu/sale/refund/RepoSelfRefundCreate.vue'),
                },
                {
                    name: 'menu.sale.refundIn',
                    path: 'menu/sale/refundIn',
                    meta: { title: '批发退货入库', keepAlive: true },
                    component: () => import('./views/menu/sale/RefundIn.vue'),
                },
                {
                    name: 'menu.sale.refundIn.detail',
                    path: 'menu/sale/refundIn/detail/:code',
                    meta: { title: '批发退货入库详情', keepAlive: false },
                    component: () => import('./views/menu/sale/refundIn/RefundInDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.cashRefund',
                    path: 'menu/sale/cashRefund',
                    meta: { title: '收银退货', keepAlive: true },
                    component: () => import('./views/menu/sale/CashRefund.vue'),
                },
                {
                    name: 'menu.sale.cashRefund.detail',
                    path: 'menu/sale/cashRefund/detail/:code',
                    meta: { title: '收银退货详情', keepAlive: false },
                    component: () => import('./views/menu/sale/cashRefund/CashRefundDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.cash',
                    path: 'menu/sale/cash',
                    meta: { title: '收银交易', keepAlive: true },
                    component: () => import('./views/menu/sale/Cash.vue'),
                },
                {
                    name: 'menu.sale.cash.detail',
                    path: 'menu/sale/cash/detail/:code',
                    meta: { title: '收银交易详情', keepAlive: false },
                    component: () => import('./views/menu/sale/cash/CashDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.selfRefund',
                    path: 'menu/sale/selfRefund',
                    meta: { title: '自建退货', keepAlive: true },
                    component: () => import('./views/menu/sale/SelfRefund.vue'),
                },
                {
                    name: 'menu.sale.selfRefund.detail',
                    path: 'menu/sale/selfRefund/detail/:code',
                    meta: { title: '自建退货详情', keepAlive: false },
                    component: () => import('./views/menu/sale/selfRefund/SelfRefundDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.sale.selfRefund.create',
                    path: 'menu/sale/selfRefund/create',
                    meta: { title: '新建自建退货', keepAlive: true },
                    component: () => import('./views/menu/sale/selfRefund/SelfRefundCreate.vue'),
                },
                {
                    name: 'menu.promotion.plan',
                    path: 'menu/promotion/plan',
                    meta: { title: '促销方案', keepAlive: true },
                    component: () => import('./views/menu/promotion/Plan.vue'),
                },
                {
                    name: 'menu.promotion.plan.detail',
                    path: 'menu/promotion/plan/detail/:code',
                    meta: { title: '促销方案详情', keepAlive: false },
                    component: () => import('./views/menu/promotion/plan/PlanDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.promotion.plan.edit',
                    path: 'menu/promotion/plan/edit/:code',
                    meta: { title: '促销方案编辑', keepAlive: false },
                    component: () => import('./views/menu/promotion/plan/PlanEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.promotion.plan.create',
                    path: 'menu/promotion/plan/create',
                    meta: { title: '新建促销方案', keepAlive: true },
                    component: () => import('./views/menu/promotion/plan/PlanCreate.vue'),
                },
                {
                    name: 'menu.plan.goods',
                    path: 'menu/plan/goods',
                    meta: { title: '商品计划', keepAlive: true },
                    component: () => import('./views/menu/promotion/Programme.vue'),
                },
                {
                    name: 'menu.plan.goods.create',
                    path: 'menu/plan/goods/create',
                    meta: { title: '新建商品计划', keepAlive: true },
                    component: () => import('./views/menu/promotion/programme/ProgrammeCreate.vue'),
                },
                {
                    name: 'menu.plan.goods.detail',
                    path: 'menu/plan/goods/detail/:code',
                    meta: { title: '商品计划详情', keepAlive: false },
                    component: () => import('./views/menu/promotion/programme/ProgrammeDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.plan.goods.summary',
                    path: 'menu/plan/goods/summary/:code',
                    meta: { title: '商品计划统计', keepAlive: false },
                    component: () => import('./views/menu/promotion/programme/ProgrammeSummary.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.plan.goodsDetail.create',
                    path: 'menu/plan/goodsDetail/create',
                    meta: { title: '新建商品计划方案', keepAlive: false },
                    component: () => import('./views/menu/promotion/programme/GoodsPlanCreate.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.plan.goods.goodsPlanDeptDetail',
                    path: 'menu/plan/goods/goodsPlanDeptDetail',
                    meta: { title: '商品计划机构列表', keepAlive: false },
                    component: () => import('./views/menu/promotion/programme/GoodsPlanDeptDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.plan.goods.dailySettle',
                    path: 'menu/plan/goods/dailySettle',
                    meta: { title: '商品计划日结', keepAlive: true },
                    component: () => import('./views/menu/finance/goodsPlan/DailySettle.vue'),
                },
                {
                    name: 'menu.plan.goods.dailySettle.create',
                    path: 'menu/plan/goods/dailySettle/create',
                    meta: { title: '新建商品计划日结', keepAlive: true },
                    component: () => import('./views/menu/finance/goodsPlan/DailySettleCreate.vue'),
                },
                {
                    name: 'menu.plan.goods.dailySettle.detail',
                    path: 'menu/plan/goods/dailySettle/detail/:code',
                    meta: { title: '商品计划日结详情', keepAlive: false },
                    component: () => import('./views/menu/finance/goodsPlan/DailySettleDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                //商品计划交易列表
                {
                    name: 'menu.plan.goods.sell',
                    path: 'menu/plan/goods/sell',
                    meta: { title: '商品计划交易列表', keepAlive: true },
                    component: () => import('./views/menu/sale/GoodsPlanSell.vue'),
                },
                //电商管理--电商销售出入库
                {
                    name: 'menu.mall.manage.order',
                    path: 'menu/management/exwarehouse',
                    meta: { title: '电商销售出库', keepAlive: true },
                    component: () => import('./views/menu/management/exwarehouse.vue'),
                },
                {
                    name: 'menu.mall.manage.online.sell.count.change',
                    path: 'menu/management/commodityLendingReturnRecord',
                    meta: { title: '商品借出/还货记录表', keepAlive: true },
                    component: () => import('./views/menu/management/commodityLendingReturnRecord.vue'),
                },
                {
                    name: 'menu.mall.manage.businessPoints',
                    path: 'menu/management/businessPoints',
                    meta: { title: '商分兑换', keepAlive: true },
                    component: () => import('./views/menu/management/businessPoints.vue'),
                },
                {
                    name: 'menu.mall.manage.businessPoints.create',
                    path: 'menu/management/businessPoints/create',
                    meta: { title: '新建兑换单', keepAlive: true },
                    component: () => import('./views/menu/management/businessPoints/create.vue'),
                },
                {
                    name: 'menu.mall.manage.businessPoints.detail',
                    path: 'menu/management/businessPoints/detail',
                    meta: { title: '查看兑换单', keepAlive: true },
                    component: () => import('./views/menu/management/businessPoints/detail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.mall.manage.refund',
                    path: 'menu/management/warehousing',
                    meta: { title: '电商退货入库', keepAlive: true },
                    component: () => import('./views/menu/management/warehousing.vue'),
                },
                {
                    name: 'menu.mall.manage.order.open',
                    path: 'menu/management/exwarehouseInfo/:code',
                    meta: { title: '电商销售出库', keepAlive: true },
                    component: () => import('./views/menu/management/exwarehouseInfo.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.mall.manage.refund.open',
                    path: 'menu/management/warehousingInfo:code',
                    meta: { title: '电商退货入库', keepAlive: true },
                    component: () => import('./views/menu/management/warehousingInfo.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                //补货管理
                {
                    name: 'menu.mall.manage.repay.goods',
                    path: 'menu/management/replenishment',
                    meta: { title: '门店补货管理', keepAlive: true },
                    component: () => import('./views/menu/management/replenishment.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                //补货编辑，新建
                {
                    name: 'menu.mall.manage.repay.good.detail',
                    path: 'menu/management/replenishmentAll/detail',
                    meta: { title: '补货单详情', keepAlive: true },
                    component: () => import('./views/menu/management/replenishmentAll/replenishmentDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.mall.manage.repay.goods.edit',
                    path: 'menu/management/replenishmentAll/edit',
                    meta: { title: '编辑补货单', keepAlive: false },
                    component: () => import('./views/menu/management/replenishmentAll/replenishmentEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.mall.manage.repay.goods.create',
                    path: 'menu/management/replenishmentAll/create',
                    meta: { title: '新建补货单', keepAlive: true },
                    component: () => import('./views/menu/management/replenishmentAll/replenishmentCreate.vue'),
                },
                //收银日结
                {
                    name: 'menu.finance.dailySettle',
                    path: 'menu/finance/dailySettle',
                    meta: { title: '收银日结', keepAlive: true },
                    component: () => import('./views/menu/finance/DailySettle.vue'),
                },
                {
                    name: 'menu.finance.dailySettle.create',
                    path: 'menu/finance/dailySettle/create',
                    meta: { title: '新建收银日结', keepAlive: true },
                    component: () => import('./views/menu/finance/dailySettle/DailySettleCreate.vue'),
                },
                {
                    name: 'menu.finance.dailySettle.detail',
                    path: 'menu/finance/dailySettle/detail/:code',
                    meta: { title: '收银日结详情', keepAlive: false },
                    component: () => import('./views/menu/finance/dailySettle/DailySettleDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.finance.dailySettle.queryNoSyncRecord',
                    path: 'menu/finance/dailySettle/queryNoSyncRecord',
                    meta: { title: '交易同步失败记录查询', keepAlive: true },
                    component: () => import('views/menu/finance/dailySettle/QueryNoSyncRecord.vue'),
                },
                {
                    name: 'menu.finance.dailySettle.queryThirdTradeSyncRecord',
                    path: 'menu/finance/dailySettle/queryThirdTradeSyncRecord',
                    meta: { title: '微信/支付宝支付成功未同步交易', keepAlive: true },
                    component: () => import('views/menu/finance/dailySettle/QueryThirdTradeSyncRecord.vue'),
                },
                //会员日结
                {
                    name: 'menu.finance.member.dailySettle',
                    path: 'menu/finance/member/dailySettle',
                    meta: { title: '会员日结', keepAlive: true },
                    component: () => import('./views/menu/finance/member/DailySettle.vue'),
                },
                {
                    name: 'menu.finance.member.dailySettle.create',
                    path: 'menu/finance/member/dailySettle/create',
                    meta: { title: '新建会员日结', keepAlive: true },
                    component: () => import('./views/menu/finance/member/DailySettleCreate.vue'),
                },
                {
                    name: 'menu.finance.member.dailySettle.detail',
                    path: 'menu/finance/member/dailySettle/detail/:code',
                    meta: { title: '会员日结详情', keepAlive: false },
                    component: () => import('./views/menu/finance/member/DailySettleDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                //支付模板
                {
                    name: 'menu.finance.payTemplate',
                    path: 'menu/finance/payTemplate',
                    meta: { title: '支付模板', keepAlive: true },
                    component: () => import('./views/menu/finance/PayTemplate.vue'),
                },
                {
                    name: 'menu.finance.payTemplate.detail',
                    path: 'menu/finance/payTemplate/detail/:code',
                    meta: { title: '支付模板详情', keepAlive: false },
                    component: () => import('./views/menu/finance/payTemplate/PayTemplateDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.finance.payTemplate.create',
                    path: 'menu/finance/payTemplate/create',
                    meta: { title: '新建支付模板', keepAlive: true },
                    component: () => import('./views/menu/finance/payTemplate/PayTemplateCreate.vue'),
                },
                {
                    name: 'menu.finance.payTemplate.edit',
                    path: 'menu/finance/payTemplate/edit',
                    meta: { title: '编辑支付模板', keepAlive: false },
                    component: () => import('./views/menu/finance/payTemplate/PayTemplateEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                //支付类型
                {
                    name: 'menu.finance.payType',
                    path: 'menu/finance/payType',
                    meta: { title: '支付类型', keepAlive: true },
                    component: () => import('./views/menu/finance/PayType.vue'),
                },
                {
                    name: 'menu.finance.payType.detail',
                    path: 'menu/finance/payType/detail/:code',
                    meta: { title: '支付类型详情', keepAlive: false },
                    component: () => import('./views/menu/finance/payType/PayTypeDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.finance.payType.edit',
                    path: 'menu/finance/payType/edit',
                    meta: { title: '编辑支付类型', keepAlive: false },
                    component: () => import('./views/menu/finance/payType/PayTypeEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                //微信日结
                {
                    name: 'menu.finance.weChat',
                    path: 'menu/finance/weChat',
                    meta: { title: '微信授权', keepAlive: true },
                    component: () => import('./views/menu/finance/WeChat.vue'),
                },
                //支付宝授权
                {
                    name: 'menu.finance.aliPayAuth',
                    path: 'menu/finance/aliPayAuth',
                    meta: { title: '支付宝授权', keepAlive: true },
                    component: () => import('./views/menu/finance/AliPayAuth.vue'),
                },
                {
                    name: 'menu.finance.dept.renew',
                    path: 'menu/finance/agencyRenewal',
                    meta: { title: '机构续费', keepAlive: true },
                    component: () => import('./views/menu/finance/agencyRenewal.vue'),
                },
                {
                    name: 'menu.member.level',
                    path: 'menu/member/level',
                    meta: { title: '会员等级设置', keepAlive: true },
                    component: () => import('./views/menu/member/Level.vue'),
                },
                {
                    name: 'menu.member.level.detail',
                    path: 'menu/member/level/detail/:code',
                    meta: { title: '会员等级设置详情', keepAlive: false },
                    component: () => import('./views/menu/member/level/LevalDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.level.edit',
                    path: 'menu/member/level/edit',
                    meta: { title: '会员等级设置编辑', keepAlive: false },
                    component: () => import('./views/menu/member/level/LevalEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.member',
                    path: 'menu/member/member',
                    meta: { title: '会员管理', keepAlive: true },
                    component: () => import('./views/menu/member/Member.vue'),
                },
                {
                    name: 'menu.member.member.detail',
                    path: 'menu/member/member/detail/:code',
                    meta: { title: '会员详情', keepAlive: false },
                    component: () => import('./views/menu/member/member/MemberDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.member.edit',
                    path: 'menu/member/member/edit/:code',
                    meta: { title: '编辑会员', keepAlive: false },
                    component: () => import('./views/menu/member/member/MemberEdit.vue'),
                    props: (route) => ({ code: route.params.code }),
                },
                {
                    name: 'menu.member.member.recharge',
                    path: 'menu/member/member/recharge/:code',
                    meta: { title: '会员充值', keepAlive: false },
                    component: () => import('./views/menu/member/member/MemberRecharge.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.member.refund',
                    path: 'menu/member/member/refund/:code',
                    meta: { title: '会员退款', keepAlive: false },
                    component: () => import('./views/menu/member/member/MemberRefund.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.member.create',
                    path: 'menu/member/member/create',
                    meta: { title: '创建会员', keepAlive: true },
                    component: () => import('./views/menu/member/member/MemberCreate.vue'),
                },
                {
                    name: 'menu.member.memberCardCreate.create',
                    path: 'menu/member/memberCardCreate/create',
                    meta: { title: '会员绑定会员卡', keepAlive: false },
                    component: () => import('./views/menu/member/member/CardCreate.vue'),
                    props: (route) => ({
                        mobile: route.params.mobile,
                        deptGroupCode: route.params.deptGroupCode,
                        deptCode: route.params.deptCode,
                    }),
                },
                {
                    name: 'menu.member.member.changeLevel',
                    path: 'menu/member/member/changeLevel',
                    meta: { title: '变更会员等级', keepAlive: false },
                    component: () => import('./views/menu/member/member/MemberLevelChange.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.pointGoods',
                    path: 'menu/member/pointGoods',
                    meta: { title: '积分商品', keepAlive: true },
                    component: () => import('./views/menu/member/PointGoods.vue'),
                },
                {
                    name: 'menu.member.pointGoods.detail',
                    path: 'menu/member/pointGoods/detail/:code',
                    meta: { title: '积分商品详情', keepAlive: false },
                    component: () => import('./views/menu/member/pointGoods/PointGoodsDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.pointGoods.edit',
                    path: 'menu/member/pointGoods/edit/:code',
                    meta: { title: '编辑积分商品', keepAlive: false },
                    component: () => import('./views/menu/member/pointGoods/PointGoodsEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.pointGoods.create',
                    path: 'menu/member/pointGoods/create',
                    meta: { title: '新建积分商品', keepAlive: true },
                    component: () => import('./views/menu/member/pointGoods/PointGoodsCreate.vue'),
                },
                {
                    name: 'menu.member.card',
                    path: 'menu/member/card',
                    meta: { title: '会员卡管理', keepAlive: true },
                    component: () => import('./views/menu/member/Card.vue'),
                },
                {
                    name: 'menu.member.card.create',
                    path: 'menu/member/card/create',
                    meta: { title: '会员绑定会员卡', keepAlive: false },
                    component: () => import('./views/menu/member/card/CardCreate.vue'),
                },
                {
                    name: 'menu.member.card.reportLoss',
                    path: 'menu/member/card/reportLoss/:code',
                    meta: { title: '挂失会员卡', keepAlive: false },
                    component: () => import('./views/menu/member/card/CardReportLoss.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.card.reportRenew',
                    path: 'menu/member/card/reportRenew/:code',
                    meta: { title: '恢复会员卡', keepAlive: false },
                    component: () => import('./views/menu/member/card/CardRenew.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.card.returnCard',
                    path: 'menu/member/card/returnCard/:code',
                    meta: { title: '会员卡退卡', keepAlive: false },
                    component: () => import('./views/menu/member/card/CardReturnCard.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.card.replaceCard',
                    path: 'menu/member/card/replaceCard/:code',
                    meta: { title: '会员卡换卡', keepAlive: false },
                    component: () => import('./views/menu/member/card/ReplaceCard.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.awardCard',
                    path: 'menu/member/awardCard',
                    meta: { title: '授卡管理', keepAlive: true },
                    component: () => import('./views/menu/member/AwardCard.vue'),
                },
                {
                    name: 'menu.member.awardCard.detail',
                    path: 'menu/member/awardCard/detail/:code',
                    meta: { title: '授卡详情', keepAlive: false },
                    component: () => import('./views/menu/member/awardCard/AwardCardDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.awardCard.edit',
                    path: 'menu/member/awardCard/edit/:code',
                    meta: { title: '编辑授卡', keepAlive: false },
                    component: () => import('./views/menu/member/awardCard/AwardCardEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.awardCard.create',
                    path: 'menu/member/awardCard/create',
                    meta: { title: '新建授卡', keepAlive: true },
                    component: () => import('./views/menu/member/awardCard/AwardCardCreate.vue'),
                },
                {
                    name: 'menu.member.pointRule',
                    path: 'menu/member/pointRule',
                    meta: { title: '会员积分规则', keepAlive: true },
                    component: () => import('./views/menu/member/PointRule.vue'),
                },
                {
                    name: 'menu.member.pointRule.detail',
                    path: 'menu/member/pointRule/detail/:code',
                    meta: { title: '会员积分规则详情', keepAlive: false },
                    component: () => import('./views/menu/member/pointRule/PointRuleDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.pointRule.edit',
                    path: 'menu/member/pointRule/edit/:code',
                    meta: { title: '编辑会员积分规则', keepAlive: false },
                    component: () => import('./views/menu/member/pointRule/PointRuleEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.cardType',
                    path: 'menu/member/cardType',
                    meta: { title: '卡类名称管理', keepAlive: true },
                    component: () => import('./views/menu/member/CardType.vue'),
                },
                {
                    name: 'menu.member.import',
                    path: 'menu/member/import',
                    meta: { title: '会员信息导入', keepAlive: true },
                    component: () => import('./views/menu/member/Import.vue'),
                },
                {
                    name: 'menu.member.import.detail',
                    path: 'menu/member/import/detail/:code',
                    meta: { title: '会员信息导入详情', keepAlive: false },
                    component: () => import('./views/menu/member/import/ImportDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.import.edit',
                    path: 'menu/member/import/edit/:code',
                    meta: { title: '编辑会员信息导入', keepAlive: false },
                    component: () => import('./views/menu/member/import/ImportEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.member.import.create',
                    path: 'menu/member/import/create',
                    meta: { title: '会员信息导入', keepAlive: true },
                    component: () => import('./views/menu/member/import/ImportCreate.vue'),
                },
                {
                    name: 'menu.report.global',
                    path: 'menu/report/global',
                    meta: { title: '全局月结设置', keepAlive: false },
                    component: () => import('./views/menu/report/Global.vue'),
                },
                {
                    name: 'menu.report.setting',
                    path: 'menu/report/setting',
                    meta: { title: '月结设置', keepAlive: false },
                    component: () => import('./views/menu/report/Setting.vue'),
                },
                {
                    name: 'menu.report.settle',
                    path: 'menu/report/settle',
                    meta: { title: '月结报表', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('views/menu/report/MonthSettle.vue'),
                },
                {
                    name: 'menu.report.settle.detail',
                    path: 'menu/report/settle/detail/:code',
                    meta: { title: '月结详情', keepAlive: false, showDeptEffectiveDialog: true },
                    component: () => import('./views/menu/report/settle/SettleDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.report.member.monthSettle',
                    path: 'menu/report/member/monthSettle',
                    meta: { title: '会员月结', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('views/menu/report/member/MemberMonthSettle.vue'),
                },
                {
                    name: 'menu.report.member.monthSettle.detail',
                    path: 'menu/report/member/monthSettle/detail/:code',
                    meta: { title: '会员月结详情', keepAlive: false, showDeptEffectiveDialog: true },
                    component: () => import('./views/menu/report/member/MemberMonthSettleDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.report.inOutDetail',
                    path: 'menu/report/inOutDetail',
                    meta: { title: '出入库明细', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('./views/menu/report/InOutDetail.vue'),
                },
                {
                    name: 'menu.report.inOutSummary',
                    path: 'menu/report/inOutSummary',
                    meta: { title: '出入库汇总', keepAlive: true, showDeptEffectiveDialog: true },
                    component: () => import('./views/menu/report/InOutSummary.vue'),
                },
                {
                    name: 'menu.report.purchase.inOutDetail',
                    path: 'menu/report/purchase/inOutDetail',
                    meta: { title: '采购明细', keepAlive: true },
                    component: () => import('./views/menu/report/purchase/InOutDetail.vue'),
                },
                {
                    name: 'menu.report.purchase.inOutSummary',
                    path: 'menu/report/purchase/inOutSummary',
                    meta: { title: '采购汇总', keepAlive: true },
                    component: () => import('./views/menu/report/purchase/InOutSummary.vue'),
                },
                {
                    name: 'menu.report.member.rechargeRefundDetail',
                    path: 'menu/report/member/rechargeRefundDetail',
                    meta: { title: '会员充值退款明细', keepAlive: true },
                    component: () => import('./views/menu/report/member/RechargeRefundDetail.vue'),
                },
                {
                    name: 'menu.report.member.rechargeRefundSummary',
                    path: 'menu/report/member/rechargeRefundSummary',
                    meta: { title: '会员充值退款汇总', keepAlive: false },
                    component: () => import('./views/menu/report/member/RechargeRefundSummary.vue'),
                },
                {
                    name: 'menu.report.cashier',
                    path: 'menu/report/cashier',
                    meta: { title: '收银员交易汇总', keepAlive: true },
                    component: () => import('./views/menu/report/cash/CashierTrade.vue'),
                },
                {
                    name: 'menu.report.memberSummary',
                    path: 'menu/report/memberSummary',
                    meta: { title: '会员交易汇总', keepAlive: true },
                    component: () => import('./views/menu/report/MemberConsumeSummary.vue'),
                },
                {
                    name: 'menu.report.cashier.detail',
                    path: 'menu/report/cashier/detail',
                    meta: { title: '收银员交易汇总详情', keepAlive: false },
                    component: () => import('./views/menu/report/cash/CashierTradeDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.report.cashier.detail.note',
                    path: 'menu/report/cashier/detail/note',
                    meta: { title: '收银员交易汇总小票详情', keepAlive: false },
                    component: () => import('./views/menu/report/cash/CashierTradeNoteDetail.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.report.member.trade',
                    path: 'menu/report/member/trade',
                    meta: { title: '会员60天交易', keepAlive: true },
                    component: () => import('./views/menu/report/member/MemberInfo.vue'),
                },
                {
                    name: 'menu.report.goods.sales.trade.deptGoodsDayTradeSum',
                    path: 'menu/report/goods/sales/trade/deptGoodsDayTradeSum',
                    meta: { title: '机构商品每日交易汇总', keepAlive: true },
                    component: () => import('./views/menu/report/goods/sales/DeptGoodsDayTradeSum.vue'),
                },
                {
                    name: 'menu.report.goods.sales.refund.deptGoodsDayTradeRefundSum',
                    path: 'menu/report/goods/sales/refund/deptGoodsDayTradeRefundSum',
                    meta: { title: '机构商品每日交易退款汇总', keepAlive: true },
                    component: () => import('./views/menu/report/goods/sales/DeptGoodsDayTradeRefundSum.vue'),
                },
                {
                    name: 'menu.report.dataDisplay',
                    path: 'menu/report/dataDisplay',
                    meta: { title: '数据大屏', keepAlive: false },
                    component: () => import('./views/menu/report/DataDisplay'),
                },
                {
                    name: 'menu.mall.manage.deptGroupShareProfit',
                    path: 'menu/mall.manage/deptGroupShareProfit',
                    meta: { title: '市场分润报表', keepAlive: true },
                    component: () => import('./views/menu/onlineRetailers/shareProfit.vue'),
                },
                {
                    name: 'menu.mall.manage.deptShareProfit',
                    path: 'menu/mall.manage/deptShareProfit',
                    meta: { title: '门店分润报表', keepAlive: true },
                    component: () =>
                        import('./views/menu/storeProfitDistributionReport/storeProfitDistributionReport.vue'),
                },
                {
                    name: 'menu.report.distributionMemberDetail',
                    path: 'menu/report/distributionMemberDetail',
                    meta: { title: '员工推广会员明细', keepAlive: true },
                    component: () => import('./views/menu/report/DistributionMemberDetail.vue'),
                },
                {
                    name: 'menu.report.distributionMemberDeptCount',
                    path: 'menu/report/distributionMemberDeptCount',
                    meta: { title: '机构推广会员汇总', keepAlive: true },
                    component: () => import('./views/menu/report/DistributionMemberDeptCount.vue'),
                },

                {
                    name: 'menu.report.member.trade.detail',
                    path: 'menu/report/member/trade/detail',
                    meta: { title: '会员60天交易详情', keepAlive: false },
                    component: () => import('./views/menu/report/member/MemberTrade.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.report.merchandising.flow',
                    path: 'menu/report/merchandising/flow',
                    meta: { title: '商品计划流水', keepAlive: true },
                    component: () => import('./views/menu/report/MerchandisingFlow.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.report.merchandising.summary',
                    path: 'menu/report/merchandising/summary',
                    meta: { title: '商品计划汇总', keepAlive: true },
                    component: () => import('./views/menu/report/MerchandisingSummary.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.notice.banned',
                    path: 'menu/system/notice/banned',
                    meta: { title: '系统通知', keepAlive: true },
                    component: () => import('./views/menu/system/Notify.vue'),
                },
                {
                    name: 'menu.system.dept',
                    path: 'menu/system/dept',
                    meta: { title: '机构管理', keepAlive: true },
                    component: () => import('./views/menu/system/Dept.vue'),
                },
                {
                    name: 'menu.system.deptGroup.deptSetting',
                    path: 'menu/system/deptGroup/deptSetting',
                    meta: { title: '机构设置', keepAlive: true },
                    component: () => import('views/menu/system/group/DeptSetting.vue'),
                    props: (route) => route.params,
                },
                {
                    name: 'menu.system.deptGroup.deptSetting.create',
                    path: 'menu/system/deptGroup/deptSetting/create',
                    meta: { title: '新建部门', keepAlive: true },
                    component: () => import('views/menu/system/group/DeptCreate.vue'),
                },
                {
                    name: 'menu.system.dept.edit',
                    path: 'menu/system/dept/edit/:code',
                    meta: { title: '编辑部门', keepAlive: false },
                    component: () => import('./views/menu/system/dept/DeptEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.deptGroup.deptSetting.edit',
                    path: 'menu/system/deptGroup/deptSetting/edit/:code',
                    meta: { title: '编辑部门', keepAlive: false },
                    component: () => import('./views/menu/system/dept/DeptEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.deptGroup',
                    path: 'menu/system/deptGroup',
                    meta: { title: '机构组管理', keepAlive: true },
                    component: () => import('./views/menu/system/DeptGroup.vue'),
                },
                {
                    name: 'menu.system.deptGroup.create',
                    path: 'menu/system/deptGroup/create',
                    meta: { title: '创建机构组', keepAlive: true },
                    component: () => import('./views/menu/system/group/GroupCreate.vue'),
                },
                {
                    name: 'menu.system.deptGroup.edit',
                    path: 'menu/system/deptGroup/edit/:code',
                    meta: { title: '编辑机构组', keepAlive: false },
                    component: () => import('./views/menu/system/group/GroupEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.deptGroup.people',
                    path: 'menu/system/deptGroup/people/:code',
                    meta: { title: '机构组人员设置', keepAlive: false },
                    component: () => import('./views/menu/system/group/GroupPeople.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.staffDept',
                    path: 'menu/system/staffDept',
                    meta: { title: '设置部门管理员', keepAlive: false },
                    component: () => import('./views/menu/system/StaffDept.vue'),
                },
                //配送员工管理
                {
                    name: 'menu.system.staffManagement',
                    path: 'menu/system/StaffManagement',
                    meta: { title: '配送员工管理', keepAlive: true },
                    component: () => import('./views/menu/system/StaffManagement.vue'),
                },
                {
                    name: 'menu.system.role',
                    path: 'menu/system/role',
                    meta: { title: '角色管理', keepAlive: true },
                    component: () => import('./views/menu/system/Role.vue'),
                },
                {
                    name: 'menu.system.role.create',
                    path: 'menu/system/role/create',
                    meta: { title: '创建角色', keepAlive: true },
                    component: () => import('./views/menu/system/role/RoleCreate.vue'),
                },
                {
                    name: 'menu.system.role.edit',
                    path: 'menu/system/role/edit/:code',
                    meta: { title: '编辑角色', keepAlive: false },
                    component: () => import('./views/menu/system/role/RoleEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.role.menu',
                    path: 'menu/system/role/menu/:code',
                    meta: { title: '编辑角色菜单', keepAlive: false },
                    component: () => import('./views/menu/system/role/RoleMenu.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.role.privilege',
                    path: 'menu/system/role/privilege/:code',
                    meta: { title: '编辑角色权限', keepAlive: false },
                    component: () => import('./views/menu/system/role/RoleConfig.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.role.menuSetting',
                    path: 'menu/system/role/menuSetting/:code',
                    meta: { title: '可用菜单配置', keepAlive: false },
                    component: () => import('./views/menu/system/role/MenuSetting.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.role.privilegeSetting',
                    path: 'menu/system/role/privilegeSetting/:code',
                    meta: { title: '可用业务配置', keepAlive: false },
                    component: () => import('./views/menu/system/role/ConfigSetting.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.deptGroup.role',
                    path: 'menu/system/groupRole',
                    meta: { title: '机构组角色管理', keepAlive: true },
                    component: () => import('./views/menu/system/GroupRole.vue'),
                },
                {
                    name: 'menu.system.deptGroup.role.create',
                    path: 'menu/system/groupRole/create',
                    meta: { title: '创建角色', keepAlive: true },
                    component: () => import('./views/menu/system/groupRole/RoleCreate.vue'),
                },
                {
                    name: 'menu.system.deptGroup.role.edit',
                    path: 'menu/system/deptGroup/role/edit/:code',
                    meta: { title: '编辑角色', keepAlive: false },
                    component: () => import('./views/menu/system/groupRole/RoleEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.deptGroup.role.menu',
                    path: 'menu/system/groupRole/menu/:code',
                    meta: { title: '编辑角色菜单', keepAlive: false },
                    component: () => import('./views/menu/system/groupRole/RoleMenu.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.deptGroup.role.privilege',
                    path: 'menu/system/groupRole/privilege/:code',
                    meta: { title: '编辑角色权限', keepAlive: false },
                    component: () => import('./views/menu/system/groupRole/RoleConfig.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.staff',
                    path: 'menu/system/staff',
                    meta: { title: '员工管理', keepAlive: true },
                    component: () => import('./views/menu/system/Staff.vue'),
                },
                {
                    name: 'menu.system.staff.create',
                    path: 'menu/system/staff/create',
                    meta: { title: '创建员工', keepAlive: true },
                    component: () => import('./views/menu/system/staff/StaffCreate.vue'),
                },
                {
                    name: 'menu.system.staff.edit',
                    path: 'menu/system/role/staff/:code',
                    meta: { title: '编辑员工', keepAlive: false },
                    component: () => import('./views/menu/system/staff/StaffEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.system.staff.info',
                    path: 'menu/system/role/staffInfo/:code',
                    meta: { title: '员工详情', keepAlive: false },
                    component: () => import('./views/menu/system/staff/StaffInfo.vue'),
                },
                {
                    name: 'menu.system.regCode',
                    path: 'menu/system/regCode',
                    meta: { title: '注册码', keepAlive: true },
                    component: () => import('./views/menu/system/RegCode.vue'),
                },
                {
                    name: 'menu.system.renewal',
                    path: 'menu/system/renewal',
                    meta: { title: '续费码', keepAlive: true },
                    component: () => import('./views/menu/system/Renewal.vue'),
                },
                {
                    name: 'menu.system.client',
                    path: 'menu/system/client',
                    meta: { title: '客户端状态', keepAlive: true },
                    component: () => import('./views/menu/system/Client.vue'),
                },
                {
                    name: 'menu.system.company',
                    path: 'menu/system/company',
                    meta: { title: '企业管理', keepAlive: true },
                    component: () => import('./views/menu/system/Company.vue'),
                },
                {
                    name: 'menu.system.HelpCenter',
                    path: 'menu/system/helpCenter',
                    meta: { title: '后台配置', keepAlive: true },
                    component: () => import('./views/menu/system/HelpCenter.vue'),
                },
                {
                    name: 'menu.user.feedBack',
                    path: 'menu/system/feedBack',
                    meta: { title: '意见反馈', keepAlive: true },
                    component: () => import('./views/menu/system/feedBack.vue'),
                },
                //新增意见反馈
                {
                    name: 'menu.user.feedBack.addFeedback',
                    path: 'menu/system/feedBack/addFeedback',
                    meta: { title: '新增意见反馈', keepAlive: false },
                    component: () => import('./views/menu/system/feedBack/addFeedback.vue'),
                },
                //查看意见反馈
                {
                    name: 'menu.user.feedBack.viewFeedback',
                    path: 'menu/system/feedBack/viewFeedback/:code',
                    meta: { title: '查看意见反馈', keepAlive: false },
                    component: () => import('./views/menu/system/feedBack/viewFeedback.vue'),
                },
                {
                    name: 'menu.system.company.create',
                    path: 'menu/system/company/create',
                    meta: { title: '新建企业', keepAlive: true },
                    component: () => import('./views/menu/system/company/CompanyCreate.vue'),
                },
                {
                    name: 'menu.system.company.edit',
                    path: 'menu/system/role/company/:code',
                    meta: { title: '编辑企业', keepAlive: false },
                    component: () => import('./views/menu/system/company/CompanyEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.advert.customer',
                    path: 'menu/advert/customer',
                    meta: { title: '广告客户管理', keepAlive: true },
                    component: () => import('./views/menu/advert/Customer.vue'),
                },
                {
                    name: 'menu.advert.customer.create',
                    path: 'menu/advert/customer/create',
                    meta: { title: '创建广告客户', keepAlive: true },
                    component: () => import('./views/menu/advert/customer/CustomerCreate.vue'),
                },
                {
                    name: 'menu.advert.customer.edit',
                    path: 'menu/advert/customer/edit/:code',
                    meta: { title: '编辑广告客户', keepAlive: false },
                    component: () => import('./views/menu/advert/customer/CustomerEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.advert.manage',
                    path: 'menu/advert/manage',
                    meta: { title: '广告管理', keepAlive: true },
                    component: () => import('./views/menu/advert/Manage.vue'),
                },
                {
                    name: 'menu.advert.manage.create',
                    path: 'menu/advert/manage/create',
                    meta: { title: '新建广告', keepAlive: true },
                    component: () => import('./views/menu/advert/manage/AdvertCreate.vue'),
                },
                {
                    name: 'menu.advert.manage.edit',
                    path: 'menu/advert/manage/edit/:code',
                    meta: { title: '编辑广告', keepAlive: false },
                    component: () => import('./views/menu/advert/manage/AdvertEdit.vue'),
                    props: (route) => ({ form: Object.assign({}, route.params.form) }),
                },
                {
                    name: 'menu.advert.launch',
                    path: 'menu/advert/launch',
                    meta: { title: '广告投放管理', keepAlive: true },
                    component: () => import('./views/menu/advert/Launch.vue'),
                },
                {
                    name: 'menu.advert.launch.advToStore',
                    path: 'menu/advert/launch/advToStore',
                    meta: { title: '按门店设置投放广告', keepAlive: false },
                    component: () => import('./views/menu/advert/launch/AdvToStore.vue'),
                },
                {
                    name: 'menu.advert.launch.storeToAdv',
                    path: 'menu/advert/launch/storeToAdv',
                    meta: { title: '按广告设置投放门店', keepAlive: false },
                    component: () => import('./views/menu/advert/launch/StoreToAdv.vue'),
                },

                { path: '*', redirect: 'menu/console' },
            ],
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('./views/Register.vue'),
        },
        {
            path: '/repass',
            name: 'repass',
            component: () => import('./views/Repass.vue'),
        },
    ],
});

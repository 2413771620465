<template>
    <div class="EfVideo">
        <video :width="width || 100" :height="height || 100" autoplay muted loop @click="preview" class="video">
            <source :src="'http://' + src" type="video/mp4" />
        </video>
        <el-dialog title="预览" :visible.sync="dialogVisible" :before-close="handleClose">
            <video controls width="100%" height="100%" autoplay muted loop>
                <source :src="src || ''" type="video/mp4" />
            </video>
            <el-button @click="dialogVisible = false">关 闭</el-button>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'EfVideo',
    props: ['src', 'width', 'height'],
    data() {
        return {
            dialogVisible: false,
        };
    },
    methods: {
        preview() {
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
        },
    },
};
</script>

<style scoped>
.EfVideo .video:hover {
    cursor: pointer;
}
</style>

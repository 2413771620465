import { get, post } from '../http';

const baseUrl = '/stock/check/diff';

const page = (queryParam) => get(`${baseUrl}/page`, queryParam, true);

const detail = (code) => get(`${baseUrl}/info_c/${code}`);

const oneClickLossAndOverflow = (code) => post(`${baseUrl}/autoLossAndOverflowAdjustStock/${code}`);

export default { baseUrl, page, detail, oneClickLossAndOverflow };

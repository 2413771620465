import { get } from 'request/http';

const baseUrl = '/system/deptGroup';

const managedAllDeptGroups = () => {
    return get(`${baseUrl}/staffManagedAllDeptGroups`);
};

const companyAllGroup = (type) => {
    return get(`${baseUrl}/noneManaged/companyAllGroup?type=` + type || '');
};
const companyAllGroupAndDept = (type) => {
    return get(`${baseUrl}/noneManaged/companyAllGroupAndDept?type=` + type || '');
};

export default { baseUrl, managedAllDeptGroups, companyAllGroup, companyAllGroupAndDept };

import Decimal from 'decimal';
import Util from 'js/Util';

export default {
    // 返回数字
    removeFormatMoney(s) {
        return parseFloat(s.replace(/[^\d\.-]/g, ''));
    },

    formatMoney(s, type) {
        let isNegative = false;
        if (Number.parseFloat(s) < 0) {
            isNegative = true;
            s = Decimal(0).sub(Decimal(s));
        }
        if (/[^0-9\.]/.test(s)) {
            return '0.00';
        }
        if (s == null || s == 'null' || s == '') {
            return '0.00';
        }
        s = s.toString().replace(/^(\d*)$/, '$1.');
        s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
        s = s.replace('.', ',');
        const re = /(\d)(\d{3},)/;
        while (re.test(s)) {
            s = s.replace(re, '$1,$2');
        }
        s = s.replace(/,(\d\d)$/, '.$1');
        if (type == 0) {
            const a = s.split('.');
            if (a[1] == '00') {
                s = a[0];
            }
        }
        if (isNegative) {
            return '-' + s;
        }
        return s;
    },
    //该方法实现动态保留几位有效数字且自动补零功能
    formatMoney2(number, decimals, dec_point) {
        /*
         * 参数说明：
         * number：要格式化的数字
         * decimals：保留几位小数
         * dec_point：小数点符号
         * */
        number = (number + '').replace(/[^0-9+-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number;
        var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
        var dec = typeof dec_point === 'undefined' ? '.' : dec_point;
        var s = '';
        var toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return ((n * k) / k).toFixed(prec);
        };
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    },

    moneyCheck(value) {
        const reg = /^([1-9]([0-9]+)?(\.[0-9]{1,2})?)|(0)|([0-9]\.[0-9]([0-9])?)$/;
        return reg.test(value);
    },

    moneyToYuan(m) {
        if (Util.isEmpty(m)) {
            return null;
        }
        return Number.parseFloat(Decimal(m).div(10000).toNumber().toFixed(2));
    },
    moneyToThree(m) {
        if (Util.isEmpty(m)) {
            return null;
        }
        return Number.parseFloat(Decimal(m).div(10000).toNumber().toFixed(3));
    },
    moneyToFour(m) {
        if (Util.isEmpty(m)) {
            return null;
        }
        return Number.parseFloat(Decimal(m).div(10000).toNumber().toFixed(4));
    },
    moneyToDb(d) {
        if (Util.isEmpty(d)) {
            return null;
        }
        const m = d.toString().replace(/[ ]/g, '').replace(/,/gi, '');
        return Decimal(m).mul(Decimal(10000)).toNumber();
    },
    dealEmptyToZero(dealData = 0) {
        if (Util.isEmpty(dealData)) {
            return 0;
        }
        return dealData;
    },
};

!(function () {
    //验证
    var EfValid = function () {
        this.__basic = function (val, reg, required) {
            if (!required && !val) {
                return true;
            }
            if (required && !val) {
                return false;
            }
            if (!reg.test(val)) {
                return false;
            }
            return true;
        };
        this.discount = function (val, required) {
            return this.__basic(val, /^(0(\.[1-9])|[1-9](\.[0-9])?|10|10(\.0))$/, required);
        };
        //手机号
        this.mobile = function (val, required) {
            return this.__basic(val, /^1\d{10}$/, required);
        };
        this.age = function (val, required) {
            if (Number(val) == 0) {
                return true;
            }
            if (required) {
                if (!val) {
                    return false;
                }
            }
            if (!val) {
                return true;
            } else {
                if (val / 1 < 0) {
                    return false;
                } else if (val / 1 > 120) {
                    return false;
                }
                return true;
            }
        };
        this.idNumber = function (val, required) {
            if (required) {
                if (!val) {
                    return false;
                }
            } else {
                if (!val) {
                    return true;
                }
            }
            var aCity = {
                11: '北京',
                12: '天津',
                13: '河北',
                14: '山西',
                15: '内蒙古',
                21: '辽宁',
                22: '吉林',
                23: '黑龙 江',
                31: '上海',
                32: '江苏',
                33: '浙江',
                34: '安徽',
                35: '福建',
                36: '江西',
                37: '山东',
                41: '河南',
                42: '湖 北',
                43: '湖南',
                44: '广东',
                45: '广西',
                46: '海南',
                50: '重庆',
                51: '四川',
                52: '贵州',
                53: '云南',
                54: '西 藏',
                61: '陕西',
                62: '甘肃',
                63: '青海',
                64: '宁夏',
                65: '新疆',
                71: '台湾',
                81: '香港',
                82: '澳门',
                91: '国 外',
            };
            var iSum = 0;
            var sBirthday;
            var strIDno = val;
            var idCardLength = strIDno.length;
            if (!/^\d{17}(\d|x)$/i.test(strIDno) && !/^\d{15}$/i.test(strIDno)) {
                return false; //非法身份证号
            }
            if (aCity[parseInt(strIDno.substr(0, 2))] == null) {
                return false; // 非法地区
            }
            // 15位身份证转换为18位
            if (idCardLength == 15) {
                sBirthday =
                    '19' +
                    strIDno.substr(6, 2) +
                    '-' +
                    Number(strIDno.substr(8, 2)) +
                    '-' +
                    Number(strIDno.substr(10, 2));
                var d = new Date(sBirthday.replace(/-/g, '/'));
                var dd = d.getFullYear().toString() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                if (sBirthday != dd) {
                    return false;
                } //非法生日
                strIDno = strIDno.substring(0, 6) + '19' + strIDno.substring(6, 15);
                strIDno = strIDno + GetVerifyBit(strIDno);
            }
            // 判断是否大于2078年，小于1900年
            var year = strIDno.substring(6, 10);
            if (year < 1900 || year > 2078) {
                return false; //非法生日
            }
            strIDno = strIDno.replace(/x$/i, 'a');
            sBirthday =
                strIDno.substr(6, 4) + '-' + Number(strIDno.substr(10, 2)) + '-' + Number(strIDno.substr(12, 2));
            var d1 = new Date(sBirthday.replace(/-/g, '/'));
            if (sBirthday != d1.getFullYear() + '-' + (d1.getMonth() + 1) + '-' + d1.getDate()) {
                return false; //非法生日
            }
            // 身份证编码规范验证
            for (var i = 17; i >= 0; i--) {
                iSum += (Math.pow(2, i) % 11) * parseInt(strIDno.charAt(17 - i), 11);
            }
            if (iSum % 11 != 1) {
                return false; // 非法身份证号
            }
            // 判断是否屏蔽身份证
            var words = [];
            words = ['11111119111111111', '12121219121212121'];

            for (var k = 0; k < words.length; k++) {
                if (strIDno.indexOf(words[k]) != -1) {
                    return false;
                }
            }
            return true;
        };
        // 必须大写X
        this.memberIdNumber = function (val, required) {
            if (required) {
                if (!val) {
                    return false;
                }
            } else {
                if (!val) {
                    return true;
                }
            }
            var aCity = {
                11: '北京',
                12: '天津',
                13: '河北',
                14: '山西',
                15: '内蒙古',
                21: '辽宁',
                22: '吉林',
                23: '黑龙 江',
                31: '上海',
                32: '江苏',
                33: '浙江',
                34: '安徽',
                35: '福建',
                36: '江西',
                37: '山东',
                41: '河南',
                42: '湖 北',
                43: '湖南',
                44: '广东',
                45: '广西',
                46: '海南',
                50: '重庆',
                51: '四川',
                52: '贵州',
                53: '云南',
                54: '西 藏',
                61: '陕西',
                62: '甘肃',
                63: '青海',
                64: '宁夏',
                65: '新疆',
                71: '台湾',
                81: '香港',
                82: '澳门',
                91: '国 外',
            };
            var iSum = 0;
            var sBirthday;
            var strIDno = val;
            var idCardLength = strIDno.length;
            if (!/^\d{17}(\d|X)$/.test(strIDno) && !/^\d{15}$/i.test(strIDno)) {
                return false; //非法身份证号
            }
            if (aCity[parseInt(strIDno.substr(0, 2))] == null) {
                return false; // 非法地区
            }
            // 15位身份证转换为18位
            if (idCardLength == 15) {
                sBirthday =
                    '19' +
                    strIDno.substr(6, 2) +
                    '-' +
                    Number(strIDno.substr(8, 2)) +
                    '-' +
                    Number(strIDno.substr(10, 2));
                var d = new Date(sBirthday.replace(/-/g, '/'));
                var dd = d.getFullYear().toString() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                if (sBirthday != dd) {
                    return false;
                } //非法生日
                strIDno = strIDno.substring(0, 6) + '19' + strIDno.substring(6, 15);
                strIDno = strIDno + GetVerifyBit(strIDno);
            }
            // 判断是否大于2078年，小于1900年
            var year = strIDno.substring(6, 10);
            if (year < 1900 || year > 2078) {
                return false; //非法生日
            }
            strIDno = strIDno.replace(/x$/i, 'a');
            sBirthday =
                strIDno.substr(6, 4) + '-' + Number(strIDno.substr(10, 2)) + '-' + Number(strIDno.substr(12, 2));
            var d1 = new Date(sBirthday.replace(/-/g, '/'));
            if (sBirthday != d1.getFullYear() + '-' + (d1.getMonth() + 1) + '-' + d1.getDate()) {
                return false; //非法生日
            }
            // 身份证编码规范验证
            for (var i = 17; i >= 0; i--) {
                iSum += (Math.pow(2, i) % 11) * parseInt(strIDno.charAt(17 - i), 11);
            }
            if (iSum % 11 != 1) {
                return false; // 非法身份证号
            }
            // 判断是否屏蔽身份证
            var words = [];
            words = ['11111119111111111', '12121219121212121'];

            for (var k = 0; k < words.length; k++) {
                if (strIDno.indexOf(words[k]) != -1) {
                    return false;
                }
            }
            return true;
        };
        this.remark = function (val, required) {
            return this.__basic(val, /^.{1,200}$/, required);
        };
        this.skuName = function (val, required) {
            return this.__basic(val, /^[A-Za-z0-9\u4e00-\u9fa5\(\)\（\）\.\-]{1,100}$/, required);
        };
        this.specs = function (val, required) {
            return this.__basic(val, /^[A-Za-z0-9\u4e00-\u9fa5\.\/\*]{1,30}$/, required);
        };
        this.unit = function (val, required) {
            return this.__basic(val, /^[A-Za-z\u4e00-\u9fa5]{1,20}$/, required);
        };
        this.expiration = function (val, required) {
            return this.__basic(val, /^[0-9\u4e00-\u9fa5]{1,20}$/, required);
        };
        this.stock = function (val, required) {
            if (val == 0) {
                return true;
            }
            return this.__basic(val, /^(([1-9]\d+)|[0-9])(\.\d{1,3})?$/, required);
        };
    };

    const SomeRegs = function () {
        const levelNameAndUnit = /^[A-Za-z\u4e00-\u9fa5]{1,20}$/;
        return {
            money: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            //正数且最多四位小数
            money_decimal_4: /^[0-9]+([.]{1}[0-9]{1,4})?$/,
            //正数且最多三位小数
            money_decimal_3: /^[0-9]+([.]{1}[0-9]{1,3})?$/,
            //正数且最多两位小数
            money_decimal_2: /^[0-9]+([.]{1}[0-9]{1,2})?$/,
            //正负小数且最多两位小数
            settleMoney: /^-?[0-9]+([.]{1}[0-9]{1,2})?$/,
            bar: /^\d{8}$|^\d{12}$|^\d{13}$/,
            count: /^([1-9]\d*|[0]{1,1})$/,
            fastBar: /^[A-Za-z0-9\u4e00-\u9fa5]{0,15}$/,
            customBar: /^\d{0,15}$/,
            skuName: /^[A-Za-z0-9\u4e00-\u9fa5\(\)\（\）\.\-]{1,100}$/,
            specs: /^[A-Za-z0-9\u4e00-\u9fa5\.\/\*]{1,30}$/,
            unit: levelNameAndUnit,
            brandName: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
            categoryName: /^[\u4e00-\u9fa5]{1,10}$/,
            discount: /^(0(\.[1-9])|[1-9](\.[0-9])?|10|10(\.0))$/,
            memberLevelName: levelNameAndUnit,
            supplierName: /^[A-Za-z\u4e00-\u9fa5\(\)\（\）]{1,50}$/,
            userName: /^[0-9A-Za-z\_]{2,30}$/,
            realName: /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/,
            mobile: /^1\d{10}$/,
            expiration: /^[0-9\u4e00-\u9fa5]{1,20}$/,
            address: /^(\S{1,100})$/,
            //正整数不可为空
            packFactor: /^[1-9]\d*$/,
            //汉字、数字、字母最多15位且可为空
            bigUnit: /^[\u4e00-\u9fa5_a-zA-Z0-9]{0,15}$/,
        };
    };

    const Util = function () {
        this.valid = new EfValid();
        this.someRegs = new SomeRegs();
    };

    window.ef = new Util();
})();

/**
 *
 *
 * 计算身份证校验码 原理: ∑(a[i]*W[i]) mod 11 ( i = 2, 3, ..., 18 )(1) "*" 表示乘号
 * i--------表示身份证号码每一位的序号，从右至左，最左侧为18，最右侧为1。 a[i]-----表示身份证号码第 i 位上的号码
 * W[i]-----表示第 i 位上的权值 W[i] = 2^(i-1) mod 11 计算公式 (1) 令结果为 R 根据下表找出 R
 * 对应的校验码即为要求身份证号码的校验码C。 R 0 1 2 3 4 5 6 7 8 9 10 C 1 0 X 9 8 7 6 5 4 3 2 X 就是
 * 10，罗马数字中的 10 就是 X 15位转18位中,计算校验位即最后一位
 *
 *
 */
function GetVerifyBit(id) {
    var result = '';
    var nNum =
        id.charAt(0) * 7 +
        id.charAt(1) * 9 +
        id.charAt(2) * 10 +
        id.charAt(3) * 5 +
        id.charAt(4) * 8 +
        id.charAt(5) * 4 +
        id.charAt(6) * 2 +
        id.charAt(7) * 1 +
        id.charAt(8) * 6 +
        id.charAt(9) * 3 +
        id.charAt(10) * 7 +
        id.charAt(11) * 9 +
        id.charAt(12) * 10 +
        id.charAt(13) * 5 +
        id.charAt(14) * 8 +
        id.charAt(15) * 4 +
        id.charAt(16) * 2;
    nNum = nNum % 11;
    switch (nNum) {
        case 0:
            result = '1';
            break;
        case 1:
            result = '0';
            break;
        case 2:
            result = 'X';
            break;
        case 3:
            result = '9';
            break;
        case 4:
            result = '8';
            break;
        case 5:
            result = '7';
            break;
        case 6:
            result = '6';
            break;
        case 7:
            result = '5';
            break;
        case 8:
            result = '4';
            break;
        case 9:
            result = '3';
            break;
        case 10:
            result = '2';
            break;
    }
    return result;
}

import { get, post, efAxios } from '../http';
import Util from 'js/Util';

const baseUrl = '/system/staff';

const relatedStaff = (deptCode) => {
    return get(`${baseUrl}/relatedStaff`, { deptCode: deptCode }, false);
};

const allRelatedStaff = () => {
    return get(`${baseUrl}/allRelatedStaff`, null, false);
};

const staffDetail = (code) => {
    if (Util.isEmpty(code)) {
        return new Promise(() => {
            return {};
        });
    }
    return get(`${baseUrl}/info_c/${code}`);
};

const sendOldMobileValidCode = (mobile) => {
    return new Promise((resolve, reject) => {
        efAxios
            .get(`${baseUrl}/mobile/sendOldMobileValidCode/${mobile}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const checkOldMobileValidCode = (mobile, verifyCode) => {
    return new Promise((resolve, reject) => {
        efAxios
            .get(`${baseUrl}/mobile/checkOldMobileValidCode/${mobile}?verifyCode=${verifyCode}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const checkOldAccount = (params) => {
    return post(`${baseUrl}/mobile/checkOldAccount/`, params, false);
};

const sendOldMobileVerifyCode = (mobile, params) => {
    return new Promise((resolve, reject) => {
        efAxios
            .get(`${baseUrl}/mobile/sendNewMobileValidCode/${mobile}`, {
                params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const mobileUpdate = (mobile, params) => {
    return new Promise((resolve, reject) => {
        efAxios
            .get(`${baseUrl}/mobile/update/${mobile}`, {
                params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export default {
    baseUrl,
    relatedStaff,
    allRelatedStaff,
    staffDetail,
    sendOldMobileValidCode,
    checkOldMobileValidCode,
    checkOldAccount,
    sendOldMobileVerifyCode,
    mobileUpdate,
};

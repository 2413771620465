import Vue from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
import ElementUI, { Message } from 'element-ui';
import EfVideo from './views/components/EfVideo';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/theme/index.css';
import './assets/style/theme/global.css';
import './assets/font-awesome-4.7.0/css/font-awesome.min.css';
import MoneyUtils from './js/MoneyUtils.js';
import Decimal from 'decimal';
import 'js/EfUtils';
import promptMessage from 'js/pop';
import { efAxios, proxyApiHostUrlSuffix } from 'request/http';
import efApi from 'request/index.js';
import { create, all } from 'mathjs';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import xss from 'xss';
Vue.prototype.xss = xss;
// create a mathjs instance with configuration
const config = {
    number: 'BigNumber',
    precision: 20,
};
const mathjs = create(all, config);

Vue.use(promptMessage);
Vue.use(VueDOMPurifyHTML);

Vue.config.productionTip = false;
// eslint-disable-next-line vue/require-name-property
Vue.mixin({
    methods: {
        showPlanCostPrice() {
            if (this.$store.state.session.code == 'TRSSTF00000000000001') {
                return true;
            }
            return this.hasPrivilege('biz.price.plan.query');
        },
        showInPrice() {
            //是超管
            if (this.$store.state.session.code == 'TRSSTF00000000000001') {
                return true;
            }
            //门店有查看批发价权限
            if (this.$store.state.session.deptType == 3) {
                return this.hasPrivilege('biz.price.wholeSale.query');
            }
            //配送中心有查看出厂价权限
            if (this.$store.state.session.deptType == 2) {
                return this.hasPrivilege('biz.price.factory.query');
            }
            return false;
        },
        showOutPrice() {
            //是超管
            if (this.$store.state.session.code == 'TRSSTF00000000000001') {
                return true;
            }
            //门店有查看零售价权限
            if (this.$store.state.session.deptType == 3) {
                return this.hasPrivilege('biz.price.retail.query');
            }
            //配送中心有查看批发价权限
            if (this.$store.state.session.deptType == 2) {
                return this.hasPrivilege('biz.price.wholeSale.query');
            }
            return false;
        },
        hasPrivilege(flag) {
            return this.$store.state.metaAllPrivileges.indexOf(flag) >= 0;
        },
        goBack(reloadFlag = false) {
            const { routeName: currentRouteName } =
                this.$store.state.tabRoutes.find((e) => e.routeName === this.$route.name) || {};
            //找当前页面的父路由
            let goBackRoute = this.$store.state.tabRoutes
                .filter((tabRoute) => {
                    //从tabRoutes中查找当前路由的父级路由组件及自己
                    return currentRouteName.startsWith(tabRoute.routeName);
                })
                //按照路由name长度从大到小排序
                .sort((a, b) => {
                    return b.routeName.length - a.routeName.length;
                    //取出距离当前路由最近的父级路由(可能是直接父级路由，也可能是间接父级路由)或者没有
                })[1];
            //没找到父路由就找到左右两边标签
            if (!goBackRoute) {
                this.$store.state.tabRoutes.forEach((tabRoute, index) => {
                    if (tabRoute.routeName === currentRouteName) {
                        const nextToTab =
                            this.$store.state.tabRoutes[index + 1] || this.$store.state.tabRoutes[index - 1];
                        if (nextToTab) {
                            goBackRoute = nextToTab;
                        }
                    }
                });
            }
            //根据route关闭该页面
            this.$store.commit('delTabRoute', currentRouteName);
            if (goBackRoute) {
                //优先返回到最近的父级路由，没有父级路由就返回左右两边紧挨着的路由标签
                this.$router.push({
                    name: goBackRoute.routeName,
                    //从父级路由中获取params和query数据
                    params: { ...goBackRoute.params, reloadFlag },
                    query: goBackRoute.query,
                });
            }
        },
        goBackAndReload() {
            //需要重新reload页面
            this.goBack(true);
        },
        executeQueryIfNeedReload(handleQueryFun, handleReloadAllFun) {
            const routeParams = this.$route.params || {};
            if (routeParams.reloadFlag && routeParams.reloadAllFlag) {
                Message({
                    message: 'reloadFlag和reloadAllFlag不能同时设置为ture',
                    type: 'error',
                    duration: 5 * 1000,
                });
                return;
            }
            if (routeParams.reloadFlag === true) {
                delete this.$route.params.reloadFlag;
                //需要加载
                handleQueryFun();
                return;
            }
            if (routeParams.reloadAllFlag === true) {
                delete this.$route.params.reloadAllFlag;
                //先找到需要保留的参数的组件 拿到所有的属性名
                let retainComponent; //定义需要保留的组件
                if (this.$options.mixins) {
                    //查找组件
                    retainComponent = this.$options.mixins.find((component) => {
                        return component.name === 'CheckTableShowColumn';
                    });
                }
                //暂存组件原数据
                const primevalData = JSON.parse(JSON.stringify(this.$data));
                //清除所组件数据
                Object.assign(this.$data, this.$options.data());
                //还原需要保留组件的数据
                if (retainComponent) {
                    //存在该组件 就拿到该组件的保存属性值的name 根据name 将原数据中的特定组件的参数还原
                    Object.keys(retainComponent.data()).forEach((key) => {
                        this.$data[key] = primevalData[key];
                    });
                }
                handleReloadAllFun();
            }
        },
        formatDate(date, fmt) {
            if (typeof date == 'undefined' || date.constructor != Date) {
                return '';
            }
            let f = fmt || 'yyyy-MM-dd';
            const o = {
                'M+': date.getMonth() + 1, //月份
                'd+': date.getDate(), //日
                'h+': date.getHours(), //小时
                'm+': date.getMinutes(), //分
                's+': date.getSeconds(), //秒
                'q+': Math.floor((date.getMonth() + 3) / 3), //季度
                S: date.getMilliseconds(), //毫秒
            };
            if (/(y+)/.test(f)) {
                f = f.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
            }
            for (const k in o) {
                if (new RegExp('(' + k + ')').test(f)) {
                    f = f.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
                }
            }
            return f;
        },
        loadingStart(text) {
            const _loading = this.$loading({
                lock: true,
                text: text || '加载中,请稍后......',
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.7)',
                fontSize: '18px',
            });
            this.$store.commit('loadingStart', _loading);
        },
        loadingStop() {
            if (this.$store.state.loading) {
                this.$store.state.loading.close();
                this.$store.commit('loadingStop');
            }
        },
        handleSizeChange(size) {
            this.form.page = 1;
            this.form.limit = size;
            this.handleQuery();
        },
        handleCurrentChange(page) {
            this.form.page = page;
            this.handleQuery();
        },
        handlePrevClick(page) {
            this.form.page = page;
            this.handleQuery();
        },
        handleNextClick(page) {
            this.form.page = page;
            this.handleQuery();
        },
        duplicate(arr, key) {
            if (arr.length == 0) {
                return arr;
            } else {
                if (key) {
                    const obj = {};
                    const newArr = arr.reduce((cur, next) => {
                        obj[next[key]] ? '' : (obj[next[key]] = true && cur.push(next));
                        return cur;
                    }, []);
                    return newArr;
                } else {
                    return Array.from(new Set(arr));
                }
            }
        },
        defaultStartTime() {
            const nowDate = new Date();
            nowDate.setMonth(nowDate.getMonth() - 2);
            return this.formatDate(nowDate);
        },
        defaultEndTime() {
            return this.formatDate(new Date());
        },
        fmtMoney(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
        fmtMFour(money) {
            return MoneyUtils.moneyToFour(money);
        },
        fmtThree(money) {
            return MoneyUtils.moneyToThree(money);
        },
        fmtTwo(money) {
            return MoneyUtils.moneyToYuan(money);
        },
        printHTML(id, title = '八号地综合销售后台') {
            const tableHeaderInnerHtml = document.querySelector('#' + id + ' .el-table__header').innerHTML;
            const tableBodyInnerHtml = document.querySelector('#' + id + ' .el-table__body').innerHTML;
            const newWindow = window.open();
            newWindow.opener = null;
            newWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                    <head>
                        <meta charset="utf-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <meta name="viewport" content="width=device-width,initial-scale=1.0">
                        <title>${title}</title>
                        <style type="text/css">
                            @page {
                                size: A4 landscape; /*portrait： 纵向打印,  landscape: 横向*/
                            }
                            table {
                                border-collapse: collapse
                            } 
                            tbody {
                               border: 1px solid 
                            }
                            th {
                               border: 1px solid 
                            }
                            td {
                               border: 1px solid 
                            }
                        </style>
                    </head>
                    <body onload="printTable()" id="print">
                        <table>
                            ${tableHeaderInnerHtml}
                            ${tableBodyInnerHtml}  
                        </table>
                        <script>
                            function printTable() {
                              window.print();
                            }
                        </script>
                    </body>
                </html>
            `);
            newWindow.document.close();
        },
        printIdSelectorHtml(idSelectorArr = [], title = '') {
            title = '八号地综合销售后台--' + title;
            const newWindow = window.open();
            newWindow.opener = null;
            newWindow.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                        <head>
                            <meta charset="utf-8">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <meta name="viewport" content="width=device-width,initial-scale=1.0">
                            <title>${title}</title>
                            <style type="text/css">
                                @page {
                                    size: A4 landscape; /*portrait： 纵向打印,  landscape: 横向*/
                                }
                                table {
                                    border-collapse: collapse
                                } 
                                tbody {
                                   border: 1px solid 
                                }
                                th {
                                   border: 1px solid 
                                }
                                td {
                                   border: 1px solid 
                                }
                                label{
                                    text-align: right;
                                }
                                .el-form-item__content{
                                    margin-left: 0 !important;
                                }
                                .el-row{
                                    display: flex;
                                    flex-wrap: wrap;
                                }
                                .el-form-item{
                                    display: flex;
                                }
                                textarea{
                                    width: 100%;
                                    height: 80px;
                                }
                                .gutter{
                                   display: none;
                                }
                                .el-table__footer{
                                    display: contents;
                                }
                            </style>
                        </head>
                        <body onload="printPage()">
                            <script>
                                function printPage(){
                                    window.print();
                                }
                            </script>
                        </body>
                    </html>
                    `);
            const body = newWindow.document.body;
            //el-table组件打印比较特殊，需要特殊处理，因此el-table组件不能包裹到其他组件中打印，打印包含table的整个页面时，需要将页面拆分成多个id标识
            idSelectorArr.forEach((idSelector) => {
                const { id, type } = idSelector;
                if (type === 'table') {
                    document.querySelector(`#${id} .el-table__header`).childNodes.forEach((e) => {
                        body.appendChild(e.cloneNode(true));
                    });

                    document.querySelector(`#${id} .el-table__body`).childNodes.forEach((e) => {
                        body.appendChild(e.cloneNode(true));
                    });

                    document.querySelector(`.el-table__footer-wrapper`)?.childNodes.forEach((e) => {
                        body.appendChild(e.cloneNode(true));
                    });
                } else {
                    body.appendChild(document.querySelector(`#${id}`).cloneNode(true));
                }
            });
            newWindow.document.close();
        },
    },
    filters: {
        goodsType(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            }
            if (sex == '1') {
                return '女';
            }
        },
        deptType(deptType) {
            if (typeof deptType == 'undefined') {
                return '';
            }
            if (deptType == '1') {
                return '总部电商';
            }
            if (deptType == '2') {
                return '配送中心';
            }
            if (deptType == '3') {
                return '门店';
            }
        },
        reviewStatus(d) {
            if (d == 0) {
                return '待审核';
            } else if (d == 1) {
                return '审核中';
            } else if (d == 2) {
                return '已审核';
            } else if (d == -1) {
                return '已拒绝';
            }
            return '';
        },
        stockInStatus(d) {
            if (d == 0) {
                return '待入库';
            } else if (d == 1) {
                return '已入库';
            }
            return '';
        },
        stockOutStatus(d) {
            if (d == 0) {
                return '待出库';
            } else if (d == 1) {
                return '已出库';
            }
            return '';
        },
        repayDeptGoodsStatus(d) {
            if (d == 1) {
                return '待入库';
            } else if (d == 2) {
                return '已入库';
            }
            return '';
        },
        money(money) {
            if (money == null) {
                return '';
            } else if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            } else {
                return '';
            }
        },
        mFour(money) {
            if (typeof money != 'undefined') {
                if (money == 0) {
                    return '';
                }
                return Decimal(money).div(10000).toNumber().toFixed(4);
            }
            return '';
        },
        moneyToThree(m) {
            return MoneyUtils.moneyToThree(m);
        },
        moneyToYuan(m) {
            return MoneyUtils.moneyToYuan(m);
        },
        moneyToFour(m) {
            return MoneyUtils.moneyToFour(m);
        },
    },
});

Vue.prototype.$http = efAxios;
Vue.prototype.$math = mathjs;
Vue.prototype['$efApi'] = efApi;
Vue.prototype['$proxyApiHostUrlSuffix'] = proxyApiHostUrlSuffix;
Vue.prototype.decimal = Decimal;
Vue.use(ElementUI);
Vue.use(EfVideo);
// Vue.use(Print);

router.afterEach((to) => {
    store.commit('findRoutePath', to.name);
});

// eslint-disable-next-line vue/require-name-property
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

import Util from 'js/Util';
import { proxyApiHostUrlSuffix } from 'request/http';

const baseUrl = '/member/import';
const importMemberUrl = proxyApiHostUrlSuffix + `${baseUrl}/importMember`;

const exportTemplate = (deptCode = '') => {
    if (Util.isEmpty(deptCode)) {
        return;
    }
    window.open(proxyApiHostUrlSuffix + `${baseUrl}/exportTemplate?deptCode=${deptCode}`);
};
const exportCanNotImportData = (code) => {
    window.open(proxyApiHostUrlSuffix + `${baseUrl}/exportCanNotImportData?code=${code}`);
};

export default { baseUrl, importMemberUrl, exportTemplate, exportCanNotImportData };

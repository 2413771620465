import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import qs from 'qs';
import { endLoading, startLoading } from 'js/Loading';
import Util from 'js/Util';

export const proxyApiHostUrlSuffix = process.env.VUE_APP_proxy_apiHostUrl_suffix;
axios.defaults.baseURL = proxyApiHostUrlSuffix;
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
//超时时间：60s
axios.defaults.timeout = 60000;
axios.defaults.transformRequest = [
    function (data, headers) {
        if (headers['Content-Type'].indexOf('multipart/form-data') >= 0) {
            return data;
        }
        return JSON.stringify(data);
    },
];
axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
    // return qs.stringify(params,{indices: false})
    // return qs.stringify(params,{arrayFormat: 'indices'})
    // return qs.stringify(params,{arrayFormat: 'brackets'})
};

// axios默认实例的response interceptor
axios.interceptors.response.use(
    (response) => {
        //-------------校验response是否返回成功，如果失败，弹框提示--------------
        //校验axios中自带的status是否成功状态
        if (response.status !== 200) {
            Message({
                message: '系统后台处理异常',
                type: 'error',
                duration: 5 * 1000,
            });
            return Promise.reject(response);
        }
        //校验自定义数据中的status（如果有该字段）是否成功状态
        const result = response.data;
        if (!Util.isEmpty(result.status) && result.status !== 200) {
            Message({
                message: result.message || 'Error',
                type: 'error',
                duration: 5 * 1000,
                dangerouslyUseHTMLString: true,
            });
            return Promise.reject(new Error(result.message || 'Error'));
        }
        //---------------------------end---------------------------
        return response;
    },
    (error) => {
        let errorMsg;
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
            errorMsg = '网络超时，请稍等片刻后刷新查看结果';
        } else {
            if (error.response && error.response.data) {
                errorMsg = error.response.data.message || '';
            } else {
                errorMsg = error.message || '';
            }
        }
        Message({
            message: errorMsg,
            type: 'error',
            duration: 5 * 1000,
        });
        return Promise.reject(error.response || { data: {} });
    }
);
//导出默认axios
export const efAxios = axios;

// create an axios instance
const http = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + http url
    // withCredentials: true, // send cookies when cross-domain requests
});

// 新创建的axios实例的http interceptor
http.interceptors.request.use(
    (config) => {
        if (config.showLoadingFlag) {
            startLoading();
        }
        // do something before http is sent

        /*if (store.getters.token) {
          // let each http carry token
          // ['X-Token'] is a custom headers key
          // please modify it according to the actual situation
          config.headers['X-Token'] = getToken()
        }*/
        return config;
    },
    (error) => {
        // do something with http error
        if (error.config.showLoadingFlag) {
            endLoading();
        }
        return Promise.reject(error);
    }
);

// 新创建的axios实例的response interceptor
http.interceptors.response.use(
    (response) => {
        if (response.config.showLoadingFlag) {
            endLoading();
        }
        //校验axios中自带的status是否成功状态
        if (response.status !== 200) {
            Message({
                message: '系统后台处理异常',
                type: 'error',
                duration: 5 * 1000,
            });
            return Promise.reject(response);
        }
        const result = response.data;
        if (Util.isEmpty(result.status)) {
            //无自定义status状态，直接返回
            return result;
        }
        //校验自定义数据中的status是否成功状态
        if (result.status !== 200) {
            Message({
                message: result.message || 'Error',
                type: 'error',
                duration: 5 * 1000,
            });
            return Promise.reject(new Error(result.message || 'Error'));
        } else {
            return result;
        }
    },
    (error) => {
        if (error.config.showLoadingFlag) {
            endLoading();
        }
        let errorMsg;
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
            errorMsg = '网络超时，请稍等片刻后刷新后查看结果';
        } else {
            if (error.response && error.response.data) {
                errorMsg = error.response.data.message || '';
            } else {
                errorMsg = error.message || '';
            }
        }
        Message({
            message: errorMsg,
            type: 'error',
            duration: 5 * 1000,
            dangerouslyUseHTMLString: true,
        });
        return Promise.reject(error);
    }
);

export function get(url, params, showLoadingFlag = false) {
    let paramClone = params;
    if ((params || {}).search) {
        paramClone = JSON.parse(JSON.stringify(params));
        const searchParam = params.search;
        paramClone.search = searchParam.replace(/%/g, '\\%');
    }
    return new Promise((resolve, reject) => {
        //axios的get请求不能传递body参数，只能传递params参数
        http.get(url, {
            params: paramClone,
            showLoadingFlag: showLoadingFlag,
        }).then((rst) => {
            if (Array.isArray(rst.data) && !Util.isEmpty(rst.count)) {
                resolve(rst);
            } else {
                resolve(rst.data);
            }
        });
    });
}

export function postAfterConfirmed(url, params, body, confirmMsg = '是否确认保存该信息?') {
    return operateAfterConfirmed(confirmMsg, () => {
        return post(url, params, body);
    });
}

export function postByFormDataAfterConfirmed(url, params, body, confirmMsg = '是否确认保存该信息?', timeout) {
    return operateAfterConfirmed(confirmMsg, () => {
        return postByFormData(url, params, body, timeout);
    });
}

export function post(url, params, body, requestConfig = {}) {
    return new Promise((resolve, reject) => {
        http.post(url, body, {
            params: params,
            showLoadingFlag: true,
            ...requestConfig,
        })
            .then((rst) => {
                resolve(rst);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function postByFormData(url, params, body, timeout) {
    return new Promise((resolve, reject) => {
        http({
            url: url,
            method: 'post',
            data: params,
            timeout: timeout,
            showLoadingFlag: true,
            transformRequest: [
                function (data, headers) {
                    let ret = '';
                    for (const it in data) {
                        const dataValue = data[it];
                        if (!Util.isEmpty(dataValue)) {
                            ret += encodeURIComponent(it) + '=' + encodeURIComponent(dataValue) + '&';
                        }
                    }
                    return ret;
                },
            ],
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        })
            .then((rst) => {
                resolve(rst);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function patchAfterConfirmed(url, params, body, confirmMsg = '是否确认暂存该信息?') {
    return operateAfterConfirmed(confirmMsg, () => {
        return patch(url, params, body);
    });
}

export function putAfterConfirmed(url, params, body, confirmMsg = '是否确认变更该信息?') {
    return operateAfterConfirmed(confirmMsg, () => {
        return put(url, params, body);
    });
}

export function patchByFormDataAfterConfirmed(url, params, body, confirmMsg = '是否确认变更该信息?') {
    return operateAfterConfirmed(confirmMsg, () => {
        return patchByFormData(url, params, body);
    });
}

export function patch(url, params, body) {
    return new Promise((resolve, reject) => {
        http.patch(url, body, {
            params: params,
            showLoadingFlag: true,
        }).then((rst) => {
            resolve(rst);
        });
    });
}

export function put(url, params, body) {
    return new Promise((resolve, reject) => {
        http.put(url, body, {
            params: params,
            showLoadingFlag: true,
        }).then((rst) => {
            resolve(rst);
        });
    });
}

export function patchByFormData(url, params, body) {
    return new Promise((resolve, reject) => {
        http({
            url: url,
            method: 'patch',
            data: params,
            showLoadingFlag: true,
            transformRequest: [
                function (data, headers) {
                    let ret = '';
                    for (const it in data) {
                        const dataValue = data[it];
                        if (!Util.isEmpty(dataValue)) {
                            ret += encodeURIComponent(it) + '=' + encodeURIComponent(dataValue) + '&';
                        }
                    }
                    return ret;
                },
            ],
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        })
            .then((rst) => {
                resolve(rst);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function delAfterConfirmed(url, params, confirmMsg = '是否删除该信息,删除后不可恢复,是否确认删除?') {
    return operateAfterConfirmed(confirmMsg, () => {
        return del(url, params);
    });
}

export function del(url, params) {
    return new Promise((resolve, reject) => {
        http.delete(url, {
            params: params,
            showLoadingFlag: true,
        }).then((rst) => {
            resolve(rst);
        });
    });
}

async function operateAfterConfirmed(confirmMsg, operatePromise, successCallbackPromise) {
    await MessageBox.confirm(confirmMsg, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
    })
        .then(async () => {
            await operatePromise().then(() => {
                if (successCallbackPromise) {
                    return successCallbackPromise();
                } else {
                    return Message.success({ message: '操作成功' });
                }
            });
        })
        .catch(() => {
            //抛出异常，中断后续代码执行，且需要return Promise
            return Promise.reject('取消操作/关闭确认');
        });
}

import { get } from '../http';

const baseUrl = '/report/wma/stockCostChange';
const deptSummaryList = (queryDeptSummaryParam) => get(`${baseUrl}/deptSummaryList`, queryDeptSummaryParam, true);

const detailSummaryList = (querySummaryParam) => get(`${baseUrl}/detailSummaryList`, querySummaryParam, true);

const list = (queryParam) => get(`${baseUrl}/list`, queryParam, true);

const detailGrossProfitList = (querySummaryParam) => get(`${baseUrl}/detailGrossProfitList`, querySummaryParam, true);

const deptGrossProfitList = (queryDeptSummaryParam) =>
    get(`${baseUrl}/deptGrossProfitList`, queryDeptSummaryParam, true);

export default {
    baseUrl,
    deptSummaryList,
    detailSummaryList,
    list,
    detailGrossProfitList,
    deptGrossProfitList,
};

import { delAfterConfirmed, get, patchAfterConfirmed } from 'request/http';

const baseUrl = '/goods/price';

const detail = (code) => get(`${baseUrl}/info_c/${code}`);

const pageExtend = (param) => get(`${baseUrl}/pageExtend`, param, true);

const update = (code, updateParam) => patchAfterConfirmed(`${baseUrl}/patch_c/${code}`, updateParam);

const deleteBiz = (code) => delAfterConfirmed(`${baseUrl}/delete`, { code: code });

export default { baseUrl, detail, pageExtend, update, deleteBiz };

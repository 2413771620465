import { get, postAfterConfirmed, postByFormData } from 'request/http';

const baseUrl = '/goods/goods';

const queryGoods = (queryParam) => {
    return get(`${baseUrl}/pageExtend`, queryParam, true);
};

const goodsList = (queryParam) => {
    return get(`${baseUrl}/list`, queryParam, true);
};

const goodsListByCodes = (codes) => {
    if (!codes || codes.length <= 0) {
        return new Promise((resolve) => {
            resolve([]);
        });
    }
    return postByFormData(`${baseUrl}/list_c`, { codes: codes }).then((rst) => {
        return Promise.resolve(rst.data);
    });
};

const copyGoods = (param) => postAfterConfirmed(`${baseUrl}/createCopiedGoods`, param);

export default { baseUrl, queryGoods, goodsList, goodsListByCodes, copyGoods };

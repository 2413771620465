import { get } from '../http';

const baseUrl = '/promotionData';
const distributionMemberDeptCountList = (querydistributionMemberDetailListParam) =>
    get(`${baseUrl}/promotionMemberDepartmentCountQueryForSystemC`, querydistributionMemberDetailListParam, true);

export default {
    baseUrl,
    distributionMemberDeptCountList,
};

import { delAfterConfirmed, get, patchAfterConfirmed, postAfterConfirmed } from '../http';

const baseUrl = '/stock/abstract';

const page = (queryParam) => get(`${baseUrl}/page`, queryParam, true);

const detail = (code) => get(`${baseUrl}/info_c/${code}`);

const create = (createParam) => postAfterConfirmed(`${baseUrl}/createExtend`, createParam);

const update = (code, updateParam) => patchAfterConfirmed(`${baseUrl}/patch_c/${code}`, updateParam);

const deleteBiz = (code) => delAfterConfirmed(`${baseUrl}/delete`, { code: code });

export default { baseUrl, page, detail, create, update, deleteBiz };

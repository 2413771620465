import {
    delAfterConfirmed,
    get,
    patchAfterConfirmed,
    postAfterConfirmed,
    postByFormDataAfterConfirmed,
    patchByFormDataAfterConfirmed,
} from '../http';

const baseUrl = '/stock/check';

const page = (queryParam) => get(`${baseUrl}/page`, queryParam, true);

const detail = (code) => get(`${baseUrl}/info_c/${code}`);

const create = (createParam) => postAfterConfirmed(`${baseUrl}/createExtend`, createParam);

// 新建-暂存
const temporaryStorage = (createParam) =>
    postByFormDataAfterConfirmed(`${baseUrl}/createInitStockCheck`, createParam, null, '是否确认暂存该信息?');

// 新建-提交
const newlyBuildSubmit = (createParam, timeout) =>
    postByFormDataAfterConfirmed(
        `${baseUrl}/createInitCompletedStockCheck`,
        createParam,
        null,
        '提交后信息将不能更改，是否确认提交该信息?',
        timeout
    );

const update = (code, updateParam) => patchAfterConfirmed(`${baseUrl}/patchExtend/${code}`, updateParam);

// 编辑-暂存
const updateTemporaryStorage = (updateParam) =>
    patchByFormDataAfterConfirmed(`${baseUrl}/editInitStockCheck/`, updateParam, null, '是否确认暂存该信息?');

// 编辑-提交
const updateNewlyBuildSubmit = (updateParam) =>
    patchByFormDataAfterConfirmed(
        `${baseUrl}/editInitCompletedStockCheck/`,
        updateParam,
        null,
        '提交后信息将不能更改，是否确认提交该信息?'
    );

// 初盘-暂存
const initialOfferUpdateTemporaryStorage = (updateParam) =>
    patchAfterConfirmed(`${baseUrl}/editFirstCheck/`, {}, updateParam, '是否确认暂存该信息?');

// 初盘-提交
const initialOfferUpdateNewlyBuildSubmit = (updateParam) =>
    patchAfterConfirmed(`${baseUrl}/completeFirstCheck/`, {}, updateParam, '提交后进入复盘阶段，是否立即提交?');

// 复盘-暂存
const replayUpdateTemporaryStorage = (updateParam) =>
    patchAfterConfirmed(`${baseUrl}/editSecondCheck/`, {}, updateParam, '是否确认暂存该信息?');

// 复盘-提交
const replayUpdateNewlyBuildSubmit = (updateParam) =>
    patchAfterConfirmed(
        `${baseUrl}/completeSecondCheck/`,
        {},
        updateParam,
        '复盘期间商品盘点数量，须包括复盘期间的出库入库数量，请务必遵守此规则。提交后不可再次修改，是否提交?'
    );

const deleteBiz = (code) => delAfterConfirmed(`${baseUrl}/delete`, { code: code });

export default {
    baseUrl,
    page,
    detail,
    create,
    temporaryStorage,
    update,
    updateTemporaryStorage,
    updateNewlyBuildSubmit,
    initialOfferUpdateTemporaryStorage,
    initialOfferUpdateNewlyBuildSubmit,
    replayUpdateTemporaryStorage,
    replayUpdateNewlyBuildSubmit,
    deleteBiz,
    newlyBuildSubmit,
};

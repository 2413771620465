import {
    get,
    patchAfterConfirmed,
    patchByFormDataAfterConfirmed,
    postAfterConfirmed,
    postByFormDataAfterConfirmed,
} from '../http';

const baseUrl = '/purchase/apply';

const create = (createParam) => postAfterConfirmed(`${baseUrl}/createExtend`, createParam);

const createByFormData = (createParam) => postByFormDataAfterConfirmed(`${baseUrl}/createExtend`, createParam);

const update = (code, updateParam) => patchAfterConfirmed(`${baseUrl}/patchExtend/${code}`, updateParam);

const updateByFormData = (code, updateParam) =>
    patchByFormDataAfterConfirmed(`${baseUrl}/patchExtend/${code}`, updateParam);

const detail = (code) => get(`${baseUrl}/info_c/${code}`);

export default { baseUrl, create, createByFormData, update, updateByFormData, detail };

import { get, postAfterConfirmed } from '../http';

const baseUrl = '/finance/pay/template';

const page = (queryParam) => get(`${baseUrl}/page`, queryParam, true);

const detail = (code) => get(`${baseUrl}/info_c/${code}`);

const create = (createParam) => postAfterConfirmed(`${baseUrl}/createExtend`, createParam);

export default { baseUrl, page, detail, create };

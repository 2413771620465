//摘要api
import { get } from 'request/http';

const baseUrl = '/stock/abstract';

const queryAbstractList = (type) => {
    return get(`${baseUrl}/list`, { type: type }, true);
};

export default { baseUrl, queryAbstractList };

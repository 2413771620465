import { get } from 'request/http';
import Util from 'js/Util';

const baseUrl = '/system/deptPrivilege';

const hasDeptPrivilege = (deptCode, privilegeFlag) => {
    if (Util.isEmpty(deptCode)) {
        return new Promise(() => {
            return false;
        });
    }
    return get(`${baseUrl}/hasDeptPrivilege?flag=${privilegeFlag}&deptCodes=${deptCode}`);
};

export default { baseUrl, hasDeptPrivilege };

import { get } from '../http';

const baseUrl = '/promotionData';
const distributionMemberDetailList = (querydistributionMemberDetailListParam) =>
    get(`${baseUrl}/promotionMemberCountDetailQueryForSystemC`, querydistributionMemberDetailListParam, true);

export default {
    baseUrl,
    distributionMemberDetailList,
};

import { get } from 'request/http';

const baseUrl = '/goods/goodsStock';

const queryGoodsStock = (goodsCode) => {
    return get(`${baseUrl}/queryByGoodsCode/${goodsCode}`, null, false);
};

const queryCanOutStocks = (goodsCode) => {
    return get(`${baseUrl}/queryCanOutStocksByGoodsCode/${goodsCode}`, null, false);
};

const queryAllCanOutStocks = (goodsCodes) => {
    if (!goodsCodes || goodsCodes.length <= 0) {
        return new Promise(() => {
            return [];
        });
    }
    return get(`${baseUrl}/queryCanOutStocksByGoodsCodes`, { goodsCodes: goodsCodes }, false);
};

export default { baseUrl, queryGoodsStock, queryCanOutStocks, queryAllCanOutStocks };

<template>
    <div class="tooltip">
        <el-tooltip effect="light" placement="top" :hide-after="10000">
            <div slot="content">
                <p v-for="item in messages" :key="item">
                    {{ item }}
                </p>
            </div>
            <i class="el-icon-question" style="color: #409eff; margin-right: 15px; font-size: 15px; padding: 0" />
        </el-tooltip>
    </div>
</template>
<script>
export default {
    name: 'Popover',
    props: ['messages'],
};
</script>

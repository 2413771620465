import { delAfterConfirmed, get, patchAfterConfirmed, postAfterConfirmed } from '../http';

const baseUrl = '/goods/wma/costPrice';

const create = (deptCode, detailParams) =>
    postAfterConfirmed(`${baseUrl}/createExtend`, { deptCode: deptCode }, detailParams);

const detail = (code) => get(`/goods/wma/costPrice/info_c/${code}`);

const update = (code, newStockCostPrice) =>
    patchAfterConfirmed(`${baseUrl}/updateExtend/${code}`, { newStockCostPrice: newStockCostPrice });

const page = (queryParam) => get(`${baseUrl}/page`, queryParam, true);

const deleteBiz = (code) => delAfterConfirmed(`${baseUrl}/delete`, { code: code });

export default { baseUrl, create, detail, update, page, deleteBiz };

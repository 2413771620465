import { Loading } from 'element-ui';

let httpLoading = null;
let httpLoadingCount = 0;

export function startLoading(text) {
    //使用Element loading-start 方法
    if (!httpLoading) {
        //单例
        httpLoading = Loading.service({
            lock: true,
            text: text || '加载中,请稍后......',
            background: 'rgba(0, 0, 0, 0.7)',
            fontSize: '18px',
        });
        httpLoadingCount = 0;
    }
    httpLoadingCount++;
}

export function endLoading() {
    //使用Element loading-close 方法
    if (httpLoading) {
        httpLoadingCount--;
        if (httpLoadingCount <= 0) {
            httpLoading.close();
            httpLoading = null;
            httpLoadingCount = 0;
        }
    } else {
        httpLoadingCount = 0;
    }
}

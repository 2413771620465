import Decimal from 'decimal';
import { endLoading, startLoading } from 'js/Loading';

export default {
    Limit: 20,
    Total: 0,
    PageSizes: [20, 50, 100],
    DefaultPageSize: 50,
    PageStyle: 'prev, pager, next,total,jumper,sizes',
    mutation: {
        SELECT_DEPARTMENT: 'selectDepartment',
    },

    selectDepartment(vm, dept) {
        const _this = this;
        return (function (vm, dept) {
            vm.$store.commit(_this.mutation.SELECT_DEPARTMENT, dept);
        })(vm, dept);
    },
    nameJump(vm, name, pathStr, params) {
        if (pathStr && pathStr.length) {
            const p = {
                name: name,
                path: pathStr,
            };
            vm.$store.commit('regRouteMap', p);
        }
        vm.$router.push({ name: name, params: params });
    },

    queryTable(vm, url, params, success, failure) {
        this.queryTableFlag(vm, url, params, success, failure, true);
    },
    queryTableFlag(vm, url, params, success, failure, flag) {
        let paramClone = params;
        if (((params || {}).params || {}).search) {
            paramClone = JSON.parse(JSON.stringify(params));
            const searchParam = params.params.search;
            paramClone.params.search = searchParam.replace(/%/g, '\\%');
        }
        const _this = vm;
        if (flag) {
            startLoading();
        }
        _this.$http
            .get(url, paramClone)
            .then((rst) => {
                endLoading();
                if (rst.data.status === 200) {
                    if (success) {
                        success(rst.data);
                    } else {
                        _this.tableData = rst.data.data;
                        if (_this.page) _this.page.total = rst.data.count;
                    }
                } else {
                    if (failure) {
                        failure();
                    } else {
                        _this.tableData = [];
                        if (_this.page) _this.page.total = 0;
                    }
                }
            })
            .catch((rst) => {
                if (flag) {
                    endLoading();
                }
                //异常提醒会在拦截器里面进行提示
                if (failure) {
                    failure();
                } else {
                    _this.tableData = [];
                    if (_this.page) _this.page.total = 0;
                }
            });
    },

    reviewStatusEnum(hasBlank) {
        const dt = [
            { code: 0, label: '待审核' },
            { code: 2, label: '已审核' },
            { code: -1, label: '已拒绝' },
        ];
        return hasBlank ? [{ label: '请选择' }].concat(dt) : dt;
    },

    stockInStatusEnum(hasBlank) {
        const dt = [
            { code: 0, label: '待入库' },
            { code: 1, label: '已入库' },
        ];
        return hasBlank ? [{ label: '请选择' }].concat(dt) : dt;
    },
    stockOutStatusEnum(hasBlank) {
        const dt = [
            { code: 0, label: '待出库' },
            { code: 1, label: '已出库' },
        ];
        return hasBlank ? [{ label: '请选择' }].concat(dt) : dt;
    },
    ReviewStatusEnum: {
        NEED_REVIEW: 0,
        REVIEWED: 2,
        REFUSED: '-1',
        properties: {
            0: { desc: '待审核', status: 0 },
            2: { desc: '已审核', status: 2 },
            '-1': { desc: '已拒绝', status: -1 },
        },
    },
    allReviewStatus2Desc() {
        return [
            this.ReviewStatusEnum.properties[this.ReviewStatusEnum.NEED_REVIEW],
            this.ReviewStatusEnum.properties[this.ReviewStatusEnum.REVIEWED],
            this.ReviewStatusEnum.properties[this.ReviewStatusEnum.REFUSED],
        ];
    },
    allWmaBizType2Desc() {
        return [
            { type: 4, desc: '采购入库' },
            { type: 7, desc: '采购退货出库' },
            { type: 9, desc: '单方采购入库' },
            { type: 10, desc: '单方采购退货' },
            { type: 18, desc: '调拨入库单' },
            { type: 26, desc: '系统初始化入库' },
            { type: 30, desc: '系统初始化退货' },
            { type: 3, desc: '批发出库' },
            { type: 8, desc: '批发退货入库' },
            { type: 12, desc: '库存报损' },
            { type: 13, desc: '库存报溢' },
            { type: 17, desc: '调拨出库单' },
            { type: 19, desc: '收银交易' },
            { type: 20, desc: '收银退货' },
            { type: 23, desc: '内购' },
            { type: 24, desc: '自建退货' },
            { type: 32, desc: '商品库存成本单价调价' },
            // { type: 33, desc: '电商销售' },
            // { type: 34, desc: '电商退货' },
        ];
    },
    bizTypeDesc(bizType) {
        return (
            this.allWmaBizType2Desc().find((e) => {
                return e.type === bizType;
            }).desc || ''
        );
    },
    allWmaBizType2DescInventory() {
        return [
            { type: 4, desc: '采购入库' },
            { type: 7, desc: '采购退货出库' },
            { type: 9, desc: '单方采购入库' },
            { type: 10, desc: '单方采购退货' },
            { type: 18, desc: '调拨入库单' },
            { type: 26, desc: '系统初始化入库' },
            { type: 30, desc: '系统初始化退货' },
            { type: 3, desc: '批发出库' },
            { type: 8, desc: '批发退货入库' },
            { type: 12, desc: '库存报损' },
            { type: 13, desc: '库存报溢' },
            { type: 17, desc: '调拨出库单' },
            { type: 19, desc: '收银交易' },
            { type: 20, desc: '收银退货' },
            { type: 23, desc: '内购' },
            { type: 24, desc: '自建退货' },
            { type: 32, desc: '商品库存成本单价调价' },
            { type: 331, desc: '门店发货' },
            { type: 332, desc: '门店自提' },
            { type: 341, desc: '门店接单发货后退货' },
            { type: 342, desc: '门店自提后退货' },
            { type: 351, desc: '门店补货' },
        ];
    },
    bizTypeDescInventory(bizType) {
        return (
            this.allWmaBizType2DescInventory().find((e) => {
                return e.type === bizType;
            }).desc || ''
        );
    },

    mTwo(row, column, money) {
        const _zero = this.emptyZero ? '' : '0.00';
        if (typeof money != 'undefined') {
            if (!money) {
                return _zero;
            }
            return Decimal(money).div(10000).toNumber().toFixed(2);
        }
        return _zero;
    },
    mFour(row, column, money) {
        const _zero = this.emptyZero ? '' : '0.0000';
        if (typeof money != 'undefined') {
            if (!money) {
                return _zero;
            }
            return Decimal(money).div(10000).toNumber().toFixed(4);
        }
        return _zero;
    },
    mCount(row, column, count) {
        const _zero = this.emptyZero ? '' : '0';
        if (!count) {
            return _zero;
        }
        return count;
    },
    formatDate(date, fmt) {
        if (typeof date == 'undefined' || date.constructor != Date) {
            return '';
        }
        let f = fmt || 'yyyy-MM-dd';
        const o = {
            'M+': date.getMonth() + 1, //月份
            'd+': date.getDate(), //日
            'h+': date.getHours(), //小时
            'm+': date.getMinutes(), //分
            's+': date.getSeconds(), //秒
            'q+': Math.floor((date.getMonth() + 3) / 3), //季度
            S: date.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(f)) {
            f = f.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(f)) {
                f = f.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
            }
        }
        return f;
    },
    /**
     * 一个月前的格式化日期
     * @returns {*|string|string}
     */
    formatDateOfLastMonth() {
        const date = new Date();
        date.setMonth(date.getMonth() - 2);
        return this.formatDate(date);
    },

    /**
     * 空判断
     * @param value
     * @returns {boolean}
     */
    isEmpty(value) {
        return !(value === 0 || value === false || value);
    },
    /**
     * 复制对象属性
     * @param source
     * @param target
     */
    copyProperties(source, target) {
        //校验是否是Object
        if (Object.prototype.toString.call(source) !== '[object Object]') {
            return;
        }
        if (Object.prototype.toString.call(target) !== '[object Object]') {
            return;
        }
        Object.keys(target).forEach((key) => {
            if (source[key] !== undefined) {
                target[key] = source[key];
            }
        });
    },
    nowFormatDate() {
        return this.formatDate(new Date());
    },
    setIntervalAndTimeout(intervalCheckFunc, timeoutFunc, bizFunc, timeout) {
        const interval = setInterval(() => {
            console.warn('setInterval');
            const flag = intervalCheckFunc();
            if (flag) {
                clearInterval(interval);
                bizFunc();
            }
        }, 10);
        setTimeout(() => {
            clearInterval(interval);
            timeoutFunc();
        }, timeout);
    },

    /**
     * 正则校验数据
     * @param val
     * @param reg
     * @param required
     * @returns {boolean|*|void}
     */
    regValidateMatch(val, reg, required = true) {
        if (this.isEmpty(val)) {
            return !required;
        } else {
            return reg.test(val);
        }
    },

    // 加减乘除的四个接口
    add(a, b) {
        return Calculate.operation(a, b, 'add');
    },

    subtract(a, b) {
        return Calculate.operation(a, b, 'subtract');
    },

    multiply(a, b) {
        return Calculate.operation(a, b, 'multiply');
    },

    divide(a, b) {
        return Calculate.operation(a, b, 'divide');
    },
    // 防抖 防止表单重复提交
    debounce: (fn, t) => {
        const delay = t || 450;
        let timer;
        return function () {
            const args = arguments;
            if (timer) {
                clearTimeout(timer);
            }

            const callNow = !timer;

            timer = setTimeout(() => {
                timer = null;
            }, delay);

            if (callNow) fn.apply(this, args);
        };
    },
};

class Calculate {
    /*
     * 判断obj是否为一个整数
     */
    static isInteger(obj) {
        return Math.floor(obj) === obj;
    }

    static isNumber(obj) {
        return typeof obj === 'number' && !isNaN(obj);
    }

    /*
     * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
     * @param floatNum {number} 小数
     * @return {object}
     *   {times:100, num: 314}
     */
    static toInteger(floatNum) {
        var ret = { times: 1, num: 0 };
        if (this.isInteger(floatNum)) {
            ret.num = floatNum;
            return ret;
        }
        var strfi = floatNum + '';
        var dotPos = strfi.indexOf('.');
        var len = strfi.substr(dotPos + 1).length;
        var times = Math.pow(10, len);
        var intNum = Number(floatNum.toString().replace('.', ''));
        ret.times = times;
        ret.num = intNum;
        return ret;
    }

    /*
     * 核心方法，实现加减乘除运算，确保不丢失精度
     * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
     *
     * @param a {number} 运算数1
     * @param b {number} 运算数2
     * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
     * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
     *
     */
    static operation(a, b, op) {
        if (!this.isNumber(a) || !this.isNumber(b)) {
            throw new Error('入参不是数字');
        }
        var o1 = this.toInteger(a);
        var o2 = this.toInteger(b);
        var n1 = o1.num;
        var n2 = o2.num;
        var t1 = o1.times;
        var t2 = o2.times;
        var max = t1 > t2 ? t1 : t2;
        var result = null;
        switch (op) {
            case 'add':
                if (t1 === t2) {
                    // 两个小数位数相同
                    result = n1 + n2;
                } else if (t1 > t2) {
                    // o1 小数位 大于 o2
                    result = n1 + n2 * (t1 / t2);
                } else {
                    // o1 小数位 小于 o2
                    result = n1 * (t2 / t1) + n2;
                }
                return result / max;
            case 'subtract':
                if (t1 === t2) {
                    result = n1 - n2;
                } else if (t1 > t2) {
                    result = n1 - n2 * (t1 / t2);
                } else {
                    result = n1 * (t2 / t1) - n2;
                }
                return result / max;
            case 'multiply':
                result = (n1 * n2) / (t1 * t2);
                return result;
            case 'divide':
                result = (n1 / n2) * (t2 / t1);
                return result;
        }
    }
}

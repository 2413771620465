import Vue from 'vue';
import Vuex from 'vuex';
import Util from 'js/Util';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        formData: false,
        loading: null,
        session: {
            code: '',
            name: '',
            staffType: '',
            deptType: 0,
            // 超级业务员的标志(只针对业务型员工)
            superStaffFlag: false,
        },
        dept: {
            companyCode: '',
            groupCode: '',
            provinceCode: '',
            cityCode: '',
            districtCode: '',
            deptType: '',
            deptCode: '',
        },
        metaBrands: [],
        metaCategories: [],

        metaDeptTrees: [],
        metaDepts: [],
        metaDeptGroup: [],

        metaSupplier: [],
        metaStockAbstracts: [],

        metaCardType: [],

        metaCustomer: [],

        metaAllMenus: [],
        metaAllMenusPrivs: [],
        metaAllRoles: [],

        metaAllPrivileges: [],

        //页面路径相关
        currentPath: [],
        routeMap: {},

        tabRoutes: [],

        overDepts: [], //已经过期的店铺
    },
    mutations: {
        /*changeFormData(state,data){
            state.formData = data   //控制弹出框的多选框是否禁用
        },*/
        selectDepartment(state, deptObj) {
            Object.assign(state.dept, deptObj);
        },
        /*saveMetaAllMenus(state, metaAllMenus) {
            state.metaAllMenus = metaAllMenus;
        },
        saveMetaAllMenusPrivs(state, metaAllMenusPrivs) {
            state.metaAllMenusPrivs = metaAllMenusPrivs;
        },
        saveMetaAllRoles(state, metaAllRoles) {
            state.metaAllRoles = metaAllRoles;
        },*/
        login(state, login) {
            Object.assign(state.session, login);
        },
        /* saveMetaCategories(state, metaCategories) {
             state.metaCategories = metaCategories;
         },
         saveMetaSupplier(state, metaSupplier) {
             state.metaSupplier = metaSupplier;
         },
         saveMetaDeptGroup(state, metaDeptGroup) {
             state.metaDeptGroup = metaDeptGroup;
         },
         saveMetaBrands(state, metaBrands) {
             state.metaBrands = metaBrands;
         },
         saveDeptTrees(state, deptTrees) {
             state.deptTrees = deptTrees;
         },
         saveMetaStockAbstracts(state, abstract) {
             state.metaStockAbstracts = abstract;
         },
         saveMetaCustomer(state, metaCustomer) {
             state.metaCustomer = metaCustomer;
         },
         saveMetaCardType(state, metaCardType) {
             state.metaCardType = metaCardType;
         },
         saveMetaDept(state, metaDepts) {
             state.metaDepts = metaDepts;
         },*/
        loadingStart(state, loading) {
            state.loading = loading;
        },
        loadingStop(state) {
            state.loading = null;
        },
        allPrivileges(state, allPrivileges) {
            state.metaAllPrivileges = allPrivileges;
        },

        setSession(state, payload) {
            state.session.name = payload.realName;
            state.session.code = payload.code;
            state.session.staffType = payload.staffType;
            state.session.deptType = payload.deptType;
            state.session.superStaffFlag = payload.superStaffFlag;
        },

        regRouteMap(state, param) {
            state.routeMap[param.name] = param.path;
        },
        findRoutePath(state, flag) {
            state.currentPath = state.routeMap[flag] || [];
        },

        addTabRoute: (state, { toRoute, fromRoute }) => {
            //构造数据
            const newTabRoute = {
                routeName: toRoute.name,
                label: toRoute.meta.title,
                //是否缓存tab标志
                keepAliveFlag: toRoute.meta.keepAlive,
                //route中关联的组件的name属性
                componentNameOfRoute: toRoute.matched[toRoute.matched.length - 1].components.default.name,
                params: toRoute.params,
                query: toRoute.query,
            };
            const toRouteIndex = state.tabRoutes.findIndex((e) => e.routeName === toRoute.name);
            if (toRouteIndex >= 0) {
                //tab已存在，则替换该位置的tabRouter数据
                state.tabRoutes.splice(toRouteIndex, 1, newTabRoute);
            } else {
                //新增
                if (!Util.isEmpty(toRoute.meta.tabIndex)) {
                    //meta中有tab位置下标，则根据下标位置打开
                    state.tabRoutes.splice(toRoute.meta.tabIndex, 0, newTabRoute);
                } else {
                    //没有位置下标，则顺序依次push
                    state.tabRoutes.push(newTabRoute);
                }
            }
        },

        delTabRoute: (state, tabRouteName) => {
            for (const [i, v] of state.tabRoutes.entries()) {
                if (v.routeName === tabRouteName) {
                    state.tabRoutes.splice(i, 1);
                    break;
                }
            }
        },
        delAllTabRoutes: (state) => {
            state.tabRoutes = [];
        },
        addOverDepts: (state, param) => {
            state.overDepts = param || [];
        },
    },
    actions: {
        addTabRoute({ commit }, { toRoute, fromRoute }) {
            commit('addTabRoute', { toRoute, fromRoute });
        },
        delTabRoute({ commit, state }, tabRouteName) {
            commit('delTabRoute', tabRouteName);
        },
        delAllTabRoutes({ commit, state }) {
            commit('delAllTabRoutes');
        },
    },
});
